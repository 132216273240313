/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import { React, useEffect, useState } from 'react';
import { createCustomTheme } from '../../../theme';
import useSettings from '../../../hooks/useSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEMES } from '../../../constants';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Avatar,
  Tooltip,
  Switch,
  IconButton, Card, CircularProgress, Backdrop

} from '@material-ui/core';
import { ticketApi } from '../../../api/ticketApi';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
// import CustomerQualityComment from './CustumerQualityComments';
// import CustomerQualityCommentAdd from './CustomerQualityCommentAdd';
import toast from 'react-hot-toast';
import TicketFileDropzone from './TicketFileDropZone';
import { withStyles } from '@material-ui/styles';
// import { CircularProgress } from '@mui/material';







const severityList = [
  {
    code: '0',
    value: 'Mineur'
  },
  {
    code: '1',
    value: 'Modéré'
  },
  {
    code: '2',
    value: 'Grave'
  },
  {
    code: '3',
    value: 'Critique '
  },
];

// comment add
const LimitedBackdrop = withStyles({root: { zIndex: 1,}})(Backdrop);
const QualityTicketCreate = (props) => {
  const [show, setShow] = useState(false);
  const { user, update, setUpdate, isListTickets, listToggle, currentTab, setCurrentTab, getTickets, type } = props;
  // const [refresh, setrefresh] = useState(0);
  const [AIOptions, setAIOptions] = useState([]); 

  const [problemId, setProblemId] = useState(null);
  const [suggestionId, setSuggestionId] = useState(null);
  const [suggestion, setSuggestion] = useState(null);

  const [category, setCategory] = useState(null);
  const [membre, setMembre] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [addComment, setAddComment] = useState(true);
  const [ticketReference, setTicketReference] = useState('');
  const [ticketTitle, setTicketTitle] = useState(null);
  const [ticketService, setTicketService] = useState(null);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketSeverity, setTicketSeverity] = useState(null);
  const [ticketReferentId, setTicketReferentId] = useState(null);
  const [ticketReferentName, setTicketReferentName] = useState(null);
  const [ticketDescription, setTicketDescription] = useState(null);
  const [activateFolliwTicketStatus, setActivateFolliwTicketStatus] = useState(false);
  const [ticketComment, setTicketComment] = useState(null);
  const [ticketSolution, setTicketSolution] = useState(null);
  const [AiConfirm, setAiConfirm] = useState(false);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('primary');
  const [ticketState, setTicketState] = useState('Ouvert');
  const [ticketAcceptTracking, setTicketAcceptTracking] = useState(1);
  const [lastId, setLastId] = useState(null);
  const [listServices, setListServices] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listMembers, setListMembers] = useState([]);
  const [load, setLoad] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileValues, setFileValues] = useState([]);
  const [arrayTmp, setArrayTmp] = useState([]);
  const [loading, setLoading] = useState(false); 


  const sendFeedback = async (prob, solution) => {
      try {
       const urlTicket = `${process.env.REACT_APP_API_IA}/api/updatepert`;
           const values = {};
           values.suggestion_id = prob;
           values.solution_id = solution;
           const resp = await fetch(urlTicket, {
             method: 'POST',
             body: JSON.stringify(values),
             headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`            }
           });
           const json = await resp.json();
     } catch {
       setLoad(null);
     }
   };
  const sendSolutionToAI = async (solution) => {
      try {
       const urlTicket = `${process.env.REACT_APP_API_IA}/api/addsolution`;
           const values = {};
           values.suggestion_id = problemId;
           values.suggestion_solution = solution;
           const resp = await fetch(urlTicket, {
             method: 'POST',
             body: JSON.stringify(values),
             headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`            }
           });
           const json = await resp.json();
     } catch {
       setLoad(null);
     }
   };
  const getSuggestions = async () => {
    if (ticketService === 2 && ticketDescription) {
      setAIOptions(null);
  setLoad(true);
      // const timer = setTimeout(async () => {
  try {
        const urlTicket = `${process.env.REACT_APP_API_IA}/api/suggestion`;
            const values = {};
            values.text = ticketDescription;
            // console.log(JSON.stringify(values));
            // console.log(values);
            const resp = await fetch(urlTicket, {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
              }
            });
            const json = await resp.json();
            setLoad(false);
            if (json.id) {
              setProblemId(json.id);
              setAIOptions(json.solutions);
            }
            else setLoad(null);
            // return Promise.resolve(json.data[0]);
      } catch {
        setLoad(null);
      }
      // }, 100);
    } };
  const handleCloseImportDialog = () => {
    setOpen(false);
    setFileValues([]);
    setArrayTmp([]);
  };
  const handleSubmit = async (tiketId) => {
    await ticketApi.sendDoc(tiketId, user.id, fileValues, ticketReference,  localStorage.getItem('accessToken'));
 };
  const SaveFiles = () => {
    setOpen(false);
   
  };
  
  const handleChange = (index) => (event) => {
  event.preventDefault();
  const newArr = [...fileValues];
  newArr[index] = { ...newArr[index], [event.target.name]: event.target.value };
  setFileValues(newArr);
  };
  
  const handleFileDelete = (indexToDelete) => {
  setArrayTmp((prevArrayTmp) => {
    const updatedArrayTmp = [...prevArrayTmp];
    updatedArrayTmp.splice(indexToDelete, 1);
    return updatedArrayTmp;
  });
  setFileValues((prevFileValues) => {
    const updatedFileValues = [...prevFileValues];
    updatedFileValues.splice(indexToDelete, 1);
    return updatedFileValues;
  });
  };
  
  const handleDrop = async (acceptedFile) => {
     if (arrayTmp.length + acceptedFile.length <= 5 && acceptedFile.length > 0) { acceptedFile.forEach((file) => {
      
        arrayTmp.push({ name: file.name, type: '', file });
      });
        setFileValues(arrayTmp);
       setOpen(true);
      } else {setOpen(false); toast.error("impossible d'importer plus que 5 fichiers !")}
    };

  const { settings } = useSettings();
  const theme = createCustomTheme({ ...settings});
  const [spaceCounter, setSpaceCounter] = useState(0);

  const getLastTicketIdMemberTicket = async () => {
    try {
      const TicketLastId = await ticketApi.getLastTicketIdMemberTicket(localStorage.getItem('accessToken'));
      setLastId(parseInt(TicketLastId[0].max_ticket_id, 10) + 1);
    } catch (err) { 
      console.log(err);
    }
  };
  const getServicesMemberTicket = async () => {
    try {
      
      if (type === 'ticket') {
        const serviceList = await ticketApi.getServicesMemberTicket(localStorage.getItem('accessToken'));
      setListServices(serviceList); 
      }
      else {
         setListServices(null); 
      }
    } catch (err) { 
      console.log(err);
    }
  };
  const getCategoriesMemberTicket = async (serviceId) => {
    try {
      if (type === 'ticket') {
        const categorieList = await ticketApi.getCategoriesMemberTicket(localStorage.getItem('accessToken'), serviceId, type);
        setListCategories(categorieList);
      }
      else {
         const categorieList = await ticketApi.getCategoriesMemberTicket(localStorage.getItem('accessToken'), null , type);
         setListCategories(categorieList); 
      }
      
     
    } catch (err) {
      console.log(err);
    }
  };
  const getServiceMembersMemberTicket = async (serviceId) => {
    try {
    
          const memberList = await ticketApi.getServiceMembersMemberTicket(localStorage.getItem('accessToken'), serviceId);
      setListMembers(memberList);
       
    
    } catch (err) {
      console.log(err);
    }
  };  

  const handleShow = () => { setShow(!show); };

  const handleClose = () => {
    setShow(false);
    setListCategories([]);
    setListServices([]);
    setListMembers([]);
    setArrayTmp([]);
    setTicketTitle(null);
    setTicketService(null);
    setTicketSeverity(null);
    setTicketReferentId(null);
    setTicketReferentName(null);
    setTicketDescription(null);
    setActivateFolliwTicketStatus(false);
    setFileValues([]);
    setLastId(null);
    setTicketSolution(null);
    setTicketComment(null);
    setTicketState('Ouvert');
    setAiNotSolvedButtonColor('primary');
    setLoad(null);
    setAIOptions(null);
    setProblemId(null);
    setSuggestionId(null);
    setSuggestion(null);
    setCategory(null);
    setMembre(null);
    setSpaceCounter(0);
  };
  const generateReferenceCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const codeLength = 8;
    let referenceCode = '';
  
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      referenceCode += characters.charAt(randomIndex);
    }
    referenceCode += lastId;
    setTicketReference(referenceCode);
  };

  const handleTicketDescription = (event) => {
    setTicketDescription(event.target.value);
  };

  const handleTicketCommentChange = (event) => {
    setTicketComment(event.target.value);
  };

  const addMemberTicket = async () => {
    try {
      const ticketId =  await ticketApi.addMemberTicket(localStorage.getItem('accessToken'), ticketCategory, ticketService, ticketSeverity, ticketReferentId, ticketDescription, 0, ticketSolution, user.id, ticketReference, ticketState, ticketComment, type);
     {type === 'ticket' ? toast.success('Ticket ajouté avec succès!') : toast.success('Réclamation ajoutée avec succès!')}
     
      // console.log(ticketId);
      handleSubmit(ticketId.ticketId);
    } catch (err) {
      console.log(err);
      toast.error('Il y a eu un souci lors de la création !');
    }
  };

  const handleAdd = async () => { 
    getLastTicketIdMemberTicket();
    generateReferenceCode();
    await addMemberTicket();
    if (suggestionId !== null) { sendFeedback(problemId, suggestionId);}
    if (suggestion === null && ticketSolution !== null) {sendSolutionToAI(ticketSolution);}
    getTickets();
    setUpdate(!update);
    if (!isListTickets) listToggle();
    const chosenService = listServices?.find((service) => ticketService === service?.service_id) ?? null;
    if (user.id_service === 0 && type === 'ticket') { setCurrentTab(chosenService.service_name);} else if (chosenService?.service_name !== currentTab && type === 'ticket'  ) { setCurrentTab('ticketsPerso');} else { setCurrentTab('ticketsPerso');}
    handleClose();
  };
  
  const handleClick = async () => {setLoading(true); 
    if (type === 'ticket') {getServicesMemberTicket();}
    //  else {
      if (type !== 'ticket')
     { getCategoriesMemberTicket(null); getServiceMembersMemberTicket(null);}
    // } 
     setLoading(false); handleShow();
    };
  useEffect(() => {  if (type !== 'ticket' && listServices?.length === 0) { getServicesMemberTicket();  }});

  return (
    <>
     <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 , height: '100%'}}
                  open={loading}
                >
                  <CircularProgress 
                  color="primary"
                   />
      </LimitedBackdrop>
      <Button name="b1" color="primary" startIcon={<AddIcon fontSize="small" />} variant="contained" onClick={handleClick}> {type === 'ticket' ? 'Créer un ticket' : 'Créer une réclamation' }</Button>
      <Dialog open={open} onClose={handleCloseImportDialog}>
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText> Confirmer le nom et le type de chaque fichier à importer. </DialogContentText>
          {fileValues.map((value, index) => (
            <div style={{alignContent: 'center', alignItems: 'center', display: 'flex'}}>
              {index + 1}
              {'. '}
              <TextField
                variant="outlined"
                sx={{ m: '20px' }}
                name="name"
                label="Nom du fichier"
                onChange={handleChange(index)}
                value={value.name}
              />
              <IconButton onClick={() => handleFileDelete(index)}>
                <CloseIcon />
              </IconButton>
              <br />
            </div>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={SaveFiles} color="primary"> Enregistrer</Button>
          <Button onClick={handleCloseImportDialog} color="primary"> Annuler</Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} open={show} fullWidth="lg" maxWidth="lg">
        <DialogTitle>
          <row style={{ display: 'flex' }}>
            <div style={{ margin: '0px', width: '57%', color: theme.palette.primary.main, fontWeight: 'bold' }}>{type === 'ticket' ? 'Créer un nouveau ticket interne' : ' Créer une nouvelle réclamation'}</div>
            <row style={{ float: 'right', display: 'flex', padding: '0px' }}> </row>
          </row>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <row style={{ display: 'flex' }}>
                 {type === 'ticket' && <div style={{ width: '20%' }}>
                    {/* cjoisir le service */}
                    <Autocomplete getOptionLabel={(option) => `${option.service_name} `} options={listServices}
                      onChange={(e, value) => {
                        if (value !== null) {
                          getCategoriesMemberTicket(value.service_id);
                          getServiceMembersMemberTicket(value.num_service);
                          setTicketService(value.service_id);
                          getLastTicketIdMemberTicket();
                          generateReferenceCode();
                          setCategory(null);
                          setMembre(null);
                        }
                        else { setTicketService(null); setCategory(null); setMembre(null);}
                      }}
                      renderInput={(params) => ( <TextField {...params} fullWidth label="Service *" name="service" variant="outlined" style={{ width: '100%', marginLeft: '0%' }} /> )}
                    />
                  </div>}
                  <div style={{ width: '30%' }}>
                    {/* choisir la catégorie */}
                    <Autocomplete getOptionLabel={(option) => `${option.category_title} `} options={listCategories} value={category}
                      onChange={(e, value) => { if (value !== null) { setTicketCategory(value.category_id); setCategory(value);} else { setTicketCategory(null);}}}
                      renderInput={(params) => (<TextField {...params} fullWidth label="Catégorie *" name="Category" variant="outlined" style={{ width: '100%', marginLeft: type === "ticket" ? '15%' : '0%' }} />)} />
                  </div>
                  {/* choisir le référent */}

                 {type !== 'ticket' ? <Autocomplete  style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `} 
                   options={listMembers?.sort((a, b) => { const order = ["Direction", "Informatique", "Commercial", "Administratif"]; const indexA = order.indexOf(a.service_name);  const indexB = order.indexOf(b.service_name);
                      if (indexA > indexB) { return 1;} if (indexA < indexB) { return -1;} return 0;})}
                    groupBy={(option) => option.service_name} value={membre}
                    onChange={(e, value) => {
                      if (value !== null) {setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} 
                      else { setTicketReferentId(null);  setTicketReferentName(null); setMembre(null); }
                    }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />)}
                  /> : <Autocomplete style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `}
                   options={listMembers?.sort((a, b) => {
                      if (a.service_name === "Direction" && b.service_name !== "Direction") { return 1;} 
                      if (a.service_name !== "Direction" && b.service_name === "Direction") { return -1; }  else { return 0;  } 
                     })}
                    groupBy={(option) => option.service_name} value={membre} onChange={(e, value) => { if (value !== null) { console.log(type); setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} else {setTicketReferentId(null); setTicketReferentName(null); setMembre(null); }  }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />  )}
                  />}

                  {/* choisir le degré de gravité */}
                  <Autocomplete style={{ width: '20%' }} getOptionLabel={(option) => `${option.value} `} 
                    options={severityList} onChange={(e, value) => { if (value !== null) { setTicketSeverity(value.code); getLastTicketIdMemberTicket(); generateReferenceCode(); } else { setTicketSeverity(null); }}}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Gravité *" name="gravity" variant="outlined" style={{ width: '80%', marginLeft: type === "ticket" ? '10%' : '20%' }} /> )}
                  />
                </row>
                <row style={{ marginTop: '20px', display: 'flex', marginBottom: '10px' }}>
                  <div style={{ width: '80%' }}>
                    {/* la description */}
                    <TextField multiline rows={5} label="Description * " placeholder="Description d'incident" style={{ width: '100%' }} 
                      onChange={(e) => {  handleTicketDescription(e);
                          const numberOfSpaces = e.target.value.split(' ').length - 1;
                          setSpaceCounter(numberOfSpaces); 
                        if (e.nativeEvent.data === ' ') {
                        if (spaceCounter >= 2) {getSuggestions();}} 
                        if (e.target.value === '' || e.target.value === null) {setSpaceCounter(0);}}} />
                  </div>
                </row>
                {/* l'ajout de pièces jointes */}
                <div style={{ width: '80%'}}> <TicketFileDropzone onDrop={handleDrop} maxFiles={5} /></div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {fileValues.map((file, index) => (
                    <Card style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
                       <UploadFileIcon /> {file.name} <IconButton onClick={() =>  handleFileDelete(index)}><CloseIcon /></IconButton>
                    </Card>
                  ))}
                </div>
                {/* l'ajout des commentaires */}
                <div> 
                  <h4 style={{color: theme.palette.primary.main}}> Commentaires :</h4>
                  <div style={{ width: '95%' }}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      <Avatar sx={{ mr: 2 }} src={user.avatar} />
                      <TextField fullWidth onChange={handleTicketCommentChange} placeholder="Ajouter un commentaire..." size="small" variant="outlined" multiline rows={2} />
                    </Box>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} minWidth="25%" style={{ borderLeft: '2px solid' }}>
                {/* Suggestions IA */}
                <row style={{display: 'flex', justifyContent: 'center'}}>
                  <Typography style={{ textAlign: 'center', fontSize: '18px', color: theme.palette.primary.main, fontWeight: 'bold' }}>  Suggestions </Typography>
                </row>
                {load === false ? (<div style={{ maxHeight: '250px', height: '55%', overflowY: 'auto', width: '100%', marginBottom: '0px', marginLeft: '5px'}}>
                      {AIOptions?.sort((a, b) => b.pertinence - a.pertinence).map((options) => {
                      let buttonContent;
                      if (ticketSolution === options.solution) {
                         buttonContent = <span style={{ color: 'green', margin: '0px', padding: '0px' }}>problème résolu</span>;
                      } else {
                        buttonContent = <p style={{ margin: '0px', padding: '0px' }}>problème résolu</p>;
                      }
                      return (
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70%' }}>
                            <p style={{ fontSize: '12px' }}>{options.solution}</p>
                          </div>
                          <Button
                            style={{ marginLeft: '20px', fontSize: '12px' }}
                            onClick={() => { setSuggestionId(options.id); setSuggestion(options.solution); setTicketSolution(options.solution); setAiConfirm(true); setAiNotSolvedButtonColor('primary'); setTicketState('Résolu'); }}
                          >
                            {buttonContent}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                  ) : (
                      load === true ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45%' }}>
                          <CircularProgress />
                        </div>
                      ) : (
                          <div style={{height: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p>Aucune suggestion</p>
                          </div>
                      )
                      ) 
                  }
                <Divider style={{ marginTop: '10px' }} />
                {/* Solutions */}
                <Typography style={{ textAlign: 'center', fontSize: '18px', marginTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}> Actions correctives</Typography>
                <div style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                  <TextField multiline placeholder="Choisissez l&apos;action corrective d&apos;IA qui a résolu le problème ou proposez la vôtre ici." rows={4} value={ticketSolution}
                    style={{ width: '100%', marginTop: '6px', marginBottom: '6px' }}
                    onChange={(e) => { setTicketSolution(e.target.value);
                        if (e.target.value !== null && e.target.value !== '') { setTicketState('Résolu'); }
                        if (e.target.value !== suggestion) { setSuggestionId(null); setSuggestion(null); }
                        if (e.target.value === '') { setTicketState('Ouvert'); }
                        }}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <p style={{margin: '0px', padding: '0px', marginTop: '10px', position: 'absolute', left: '10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>(*) Champ obligatoire </p>
          <Button  color="primary" onClick={() => { if (ticketDescription !== null && ticketSeverity !== null && ticketCategory !== null) { handleShow();
                // Le dialog de suspendre le ticket ou la réclamation
                Swal.fire({
                  title: "<h5>Vous risquez de perdre les informations de ce ticket, cliquez sur Suspendre le ticket pour le sauvegarder comme suspendu</h5>",
                  icon: "question",
                  iconHtml: "!",
                  confirmButtonText: "Suspendre le ticket",
                  cancelButtonText: "Annuler le ticket",
                  confirmButtonColor: theme.palette.primary.main,
                  // denyButtonText: 'Annuler le ticket',
                  showCancelButton: true,
                  showCloseButton: true
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      const ticketId =  await ticketApi.addMemberTicket(localStorage.getItem('accessToken'), ticketService, ticketCategory, ticketSeverity, ticketReferentId, ticketDescription, 0, ticketSolution, user.id, ticketReference, "Suspendu", ticketComment, type);
                    {type === 'ticket' ? toast.success('Ticket suspendu avec succès!') : toast.success('Réclamation suspendue avec succès!'); }
                      // console.log(ticketId);
                      handleSubmit(ticketId.ticketId);
                    } catch (err) {
                      console.log(err);
                      {toast.error('Il y a eu un souci lors de la création !');}
                    }
                    setCurrentTab('ticketsPerso');
                    getTickets();
                    setUpdate(!update);
                    handleClose();
                  } else {
                    handleClose();
                  {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
                  }
                });
              } else {
                
                handleClose();
                {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
              }
            }}>
            Annuler
          </Button>
          <div title="Veuillez compléter les champs obligatoires ( * ) ">
            <Button
              onClick={handleAdd}
              color="primary"
              type="submit"     
              // title=""
             disabled={
  !(type !== 'ticket' || (type === 'ticket' && ticketService)) || // Checks if it's not a ticket type or it is a ticket type and service is provided
  !ticketSeverity || // Checks if ticketSeverity is provided
  !ticketDescription || ticketDescription === '' || // Checks if ticketDescription is provided
  !ticketCategory // Checks if ticketCategory is provided
}
            >
              {type === 'ticket' ? 'Créer le ticket' : 'Créer la réclamation'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
  </>
  );
};
export default QualityTicketCreate;
QualityTicketCreate.propTypes = {
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isListTickets: PropTypes.bool.isRequired,
  listToggle: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

