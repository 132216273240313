/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
import { React, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Box,Button,Checkbox,Divider,TextField,Grid,Dialog,DialogTitle,DialogContentText,DialogContent, DialogActions,Typography,Autocomplete,FormGroup,FormControlLabel,Avatar,Tooltip,Switch,IconButton,Select,MenuItem,Card, Backdrop} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ticketApi } from '../../../api/ticketApi';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import CustomerQualityComment from './CustumerQualityComments';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import TicketFileDropzone from '../quality/TicketFileDropZone';
import { createCustomTheme } from '../../../theme';
import useSettings from '../../../hooks/useSettings';
import { withStyles } from '@material-ui/styles';

const severityList = [
  {code: '0',value: 'Mineure'},
  {code: '1',value: 'Modérée'},
  {code: '2',value: 'Grave'},
  {code: '3',value: 'Critique'},
];

const LimitedBackdrop = withStyles({root: { zIndex: 1,}})(Backdrop);
const CustomerQualityTicketCreate = (props) => {
  const [show, setShow] = useState(false);
  const { customer, user, update, setUpdate, getTickets, type } = props;
  const [AIOptions, setAIOptions] = useState([]); 
  const { settings } = useSettings();


  const theme = createCustomTheme({...settings});
 
  const [addComment, setAddComment] = useState(true);
  const [ticketReference, setTicketReference] = useState('');
  const [ticketTitle, setTicketTitle] = useState(null);
  const [ticketService, setTicketService] = useState(null);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketSeverity, setTicketSeverity] = useState(null);
  const [ticketReferentId, setTicketReferentId] = useState(null);
  const [ticketReferentName, setTicketReferentName] = useState(null);
  const [ticketDescription, setTicketDescription] = useState(null);
  const [activateFolliwTicketStatus, setActivateFolliwTicketStatus] = useState(false);
  const [ticketComment, setTicketComment] = useState(null);
  const [ticketSolution, setTicketSolution] = useState(null);
  const [AiConfirm, setAiConfirm] = useState(false);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('primary');
  const [ticketState, setTicketState] = useState('Ouvert');
  const [ticketAcceptTracking, setTicketAcceptTracking] = useState(0);
  const [lastId, setLastId] = useState(null);
  const [listServices, setListServices] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listMembers, setListMembers] = useState([]);
  const [load, setLoad] = useState(null);
  const [arrayTmp, setArrayTmp] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [open, setOpen] = useState(false);
  const [fileValues, setFileValues] = useState([]);


  const [problemId, setProblemId] = useState(null);
 const [suggestionId, setSuggestionId] = useState(null);
 const [suggestion, setSuggestion] = useState(null);

 const [category, setCategory] = useState(null);
 const [membre, setMembre] = useState(null);

  const sendFeedback = async (prob, solution) => {
     try {
      const urlTicket = `${process.env.REACT_APP_API_IA}/api/updatepert`;
          const values = {};
          values.suggestion_id = prob;
          values.solution_id = solution;
          // console.log(JSON.stringify(values));
          // console.log(values);
          const resp = await fetch(urlTicket, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
            }
          });
          const json = await resp.json();
    } catch {
      setLoad(null);
    }
  };
    const sendSolutionToAI = async (solution) => {
     try {
      const urlTicket = `${process.env.REACT_APP_API_IA}/api/addsolution`;
          const values = {};
          values.suggestion_id = problemId;
          values.suggestion_solution = solution;
          // console.log(JSON.stringify(values));
          // console.log(values);
          const resp = await fetch(urlTicket, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
            }
          });
          const json = await resp.json();
    } catch {
      setLoad(null);
    }
  };
  const getSuggestions = async () => {
    if (ticketService === 2 && ticketDescription) {
      setAIOptions(null);
  setLoad(true);
      const timer = setTimeout(async () => {
  try {
        const urlTicket = `${process.env.REACT_APP_API_IA}/api/suggestion`;
            const values = {};
            values.text = ticketDescription;
            const resp = await fetch(urlTicket, {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
              }
            });
            const json = await resp.json();
            setLoad(false);
            if (json.id) {
              setProblemId(json.id);
              setAIOptions(json.solutions);
            }
            else setLoad(null);
            // return Promise.resolve(json.data[0]);
      } catch {
        setLoad(null);
      }
      }, 100);
    }
   
     };

  const getLastTicketId = async () => {
    try {
      const TicketLastId = await ticketApi.getLastTicketId(localStorage.getItem('accessToken'), parseInt(customer, 10));
  
      if (TicketLastId[0].max_ticket_id === null) {
        setLastId(1);
      } else {
        setLastId(parseInt(TicketLastId[0].max_ticket_id, 10) + 1);
      }
  
    } catch (err) {
      console.log(err);
    }
  };
  

  const getServices = async () => {
    try {
      if (type === 'ticket') {
      const serviceList = await ticketApi.getServices(localStorage.getItem('accessToken'), parseInt(customer, 10));
      setListServices(serviceList);}
      else {
        setListServices(null); 
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleDrop = async (acceptedFile) => {
     if (arrayTmp.length + acceptedFile.length <= 5 && acceptedFile.length > 0) { acceptedFile.forEach((file) => {
        arrayTmp.push({ name: file.name, type: '', file });
      });
        setFileValues(arrayTmp);
       setOpen(true);
      } else {setOpen(false); toast.error("impossible d'importer plus que 5 fichiers !")}
    };

  const getCategories = async (serviceId) => {
    try {
      if (type === 'ticket') {
      const categorieList = await ticketApi.getCategories(localStorage.getItem('accessToken'), parseInt(customer, 10), serviceId, type);
      setListCategories(categorieList);
      }
      else {
        const categorieList = await ticketApi.getCategories(localStorage.getItem('accessToken'), parseInt(customer, 10),null , type);
        setListCategories(categorieList); 
     }
    } catch (err) {
      console.log(err);
    }
  };

  const getServiceMembers = async (serviceId) => {
    try {
      const memberList = await ticketApi.getServiceMembers(localStorage.getItem('accessToken'), parseInt(customer, 10), serviceId);
      setListMembers(memberList);
    } catch (err) {
      console.log(err);
    }
  };  

 const handleClose = () => {
    setShow(false);
    setListCategories([]);
    setListServices([]);
    setListMembers([]);
    setArrayTmp([]);
    setTicketTitle(null);
    setTicketService(null);
    setTicketSeverity(null);
    setTicketReferentId(null);
    setTicketReferentName(null);
    setTicketCategory(null);
    setTicketDescription(null);
    setActivateFolliwTicketStatus(false);
    setFileValues([]);
    setLastId(null);
    setTicketSolution(null);
    setTicketComment(null);
    setTicketState('Ouvert');
    setAiNotSolvedButtonColor('primary');
    setLoad(null);
    setAIOptions(null);
    setProblemId(null);
    setSuggestionId(null);
    setSuggestion(null);
    setCategory(null);
    setMembre(null);
  };
  const handleShow = () => { setShow(!show); setAiNotSolvedButtonColor('primary'); };

 
  const generateReferenceCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const codeLength = 8;
    let referenceCode = '';
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      referenceCode += characters.charAt(randomIndex);
    }
    if (lastId !== null) {
      referenceCode += lastId;
    } else {
      referenceCode += 1;
    }
    setTicketReference(referenceCode);
  };

  const handleTicketDescription = (event) => {
    setTicketDescription(event.target.value);
  };

  const handleActivateFolliwTicketStatus = async () => {
    if (activateFolliwTicketStatus === true) {
      setTicketAcceptTracking(1);
      setActivateFolliwTicketStatus(false);
    } else {
      setTicketAcceptTracking(0);
      setActivateFolliwTicketStatus(true);
    }
  };

  const handleTicketCommentChange = (event) => {
    setTicketComment(event.target.value);
  };



  const handleSubmit = async (tiketId) => { await ticketApi.sendDoc(tiketId, user.id, fileValues, ticketReference, localStorage.getItem('accessToken'));
};
 const [spaceCounter, setSpaceCounter] = useState(0);

  const addLearnerTicket = async () => {
    try { 
          const ticketId = await ticketApi.addLearnerTicket(localStorage.getItem('accessToken'), ticketService, ticketCategory, ticketSeverity, ticketReferentId, ticketDescription, ticketAcceptTracking, ticketSolution, parseInt(customer, 10), user.id, ticketReference, ticketState, ticketComment, type);
    handleSubmit(ticketId.ticketId);
    console.log(parseInt(customer, 10));
    if (type === 'ticket') {  toast.success('Ticket ajouté avec succès!');} else { toast.success('Réclamation ajouté avec succès!');}
    } catch (err) {
      console.log(err);
      toast.error('Il y a eu un souci lors de la création !');
    }
  };

  const handleCloseImportDialog = () => {
    setOpen(false);
    setFileValues([]);
    setArrayTmp([]);
  };
  
  const SaveFiles = () => { setOpen(false); };

  const handleAdd = () => {
    // setTicketState('Ouvert');
    addLearnerTicket();
    if (suggestionId !== null) {
      sendFeedback(problemId, suggestionId);
    }
    if (suggestion === null && ticketSolution !== null) {
      sendSolutionToAI(ticketSolution);
    }
    setUpdate(!update);
    handleClose();
  };
  
  const handleClick = async () => {setLoading(true); if (type === 'ticket') {getServices();} else {getCategories(null)} getServiceMembers(null); setLoading(false); handleShow(); getLastTicketId()};
  useEffect(() => {  if (type !== 'ticket' && listServices?.length === 0) { getServices();  }});

  const handleChange = (index) => (event) => {
    event.preventDefault();
    const newArr = [...fileValues];
    newArr[index] = { ...newArr[index], [event.target.name]: event.target.value };
    setFileValues(newArr);
  };


    const handleFileDelete = (indexToDelete) => {
      setArrayTmp((prevArrayTmp) => {
        const updatedArrayTmp = [...prevArrayTmp];
        updatedArrayTmp.splice(indexToDelete, 1);
        return updatedArrayTmp;
      });
      setFileValues((prevFileValues) => {
        const updatedFileValues = [...prevFileValues];
        updatedFileValues.splice(indexToDelete, 1);
        return updatedFileValues;
      });
    };
 

  return (
    <ThemeProvider theme={theme}>
      <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 , height: '100%'}}
                  open={loading}
                >
                  <CircularProgress 
                  color="primary"
                   />
      </LimitedBackdrop>
      <Button
          color="primary"
          startIcon={<AddIcon fontSize="small" />}
          variant="contained"
          onClick={handleClick}
      >
          {type === 'ticket' ? 'Créer un ticket' : 'Créer une réclamation'}
      </Button>

      <Dialog
        open={open}
        onClose={handleCloseImportDialog}
      >
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirmer le nom et le type de chaque fichier à importer.
          </DialogContentText>
          {
  fileValues.map((value, index) => (
    <div style={{alignContent: 'center', alignItems: 'center', display: 'flex'}}>
      {index + 1}
      {'. '}
      <TextField
        variant="outlined"
        sx={{ m: '20px' }}
        name="name"
        label="Nom du fichier"
        onChange={handleChange(index)}
        value={value.name}
      />
      <IconButton onClick={() => handleFileDelete(index)}>
        <CloseIcon />
      </IconButton>
      <br />
    </div>
  ))
}
    
       </DialogContent>
        <DialogActions>
          <Button
            onClick={SaveFiles}
            color="primary"
          >
            Enregistrer
          </Button>
          <Button
            onClick={handleCloseImportDialog}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

            <Dialog
            onClose={handleClose}
                open={show}
                fullWidth="lg"
                maxWidth="lg"
            
            >
              <DialogTitle>
                <row style={{ display: 'flex' }}>
                  <div style={{ margin: '0px', width: '57%', color: theme.palette.primary.main, fontWeight: 'bold' }}>{ type === 'ticket' ? 'Créer un nouveau ticket' : 'Créer une nouvelle réclamation'}</div>
                </row>
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={9}
                    >
                      <row style={{ display: 'flex' }}>
                       {type === 'ticket' && <div style={{ width: '20%' }}>
                        <Autocomplete
                          getOptionLabel={(option) => `${option.service_name} `}
                          options={listServices}
                          onChange={(e, value) => {
                            if (value !== null) {
                              getCategories(value.service_id);
                              getServiceMembers(value.num_service);
                              setTicketService(value.service_id);
                              setCategory(null);
                              setMembre(null);
                              generateReferenceCode();
                            }
                            else {
                              setTicketService(null);
                              setCategory(null);
                              setMembre(null);
                              generateReferenceCode();
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Service *"
                              name="service"
                              variant="outlined"
                              style={{ width: '100%', marginLeft: '0%' }}
                            />
                          )}
                        /></div>}
                        <div style={{ width: '30%' }}>
                        <Autocomplete
                          getOptionLabel={(option) => `${option.category_title} `}
                          options={listCategories}
                          value={category}
                          onChange={(e, value) => {
                            if (value !== null) {
                              setTicketCategory(value.category_id);
                              setCategory(value);
                            }
                            else {
                              setTicketCategory(null);
                              setCategory(null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Catégorie *"
                              name="Category"
                              variant="outlined"
                              style={{ width: '100%', marginLeft: type === "ticket" ? '15%' : '0%' }}
                            />
                          )}
                        /></div>
                        
                        {type !== 'ticket' ? <Autocomplete  style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `} 
                   options={listMembers?.sort((a, b) => { const order = ["Direction", "Informatique", "Commercial", "Administratif"]; const indexA = order.indexOf(a.service_name);  const indexB = order.indexOf(b.service_name);
                      if (indexA > indexB) { return 1;} if (indexA < indexB) { return -1;} return 0;})}
                    groupBy={(option) => option.service_name} value={membre}
                    onChange={(e, value) => {
                      if (value !== null) {setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} 
                      else { setTicketReferentId(null);  setTicketReferentName(null); setMembre(null); }
                    }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />)}
                  /> : <Autocomplete style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `}
                   options={listMembers?.sort((a, b) => {
                      if (a.service_name === "Direction" && b.service_name !== "Direction") { return 1;} 
                      if (a.service_name !== "Direction" && b.service_name === "Direction") { return -1; }  else { return 0;  } 
                     })}
                    groupBy={(option) => option.service_name} value={membre} onChange={(e, value) => { if (value !== null) {  setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} else {setTicketReferentId(null); setTicketReferentName(null); setMembre(null); }  }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />  )}
                  />}
                        <div style={{ width: '20%' }}>
                        <Autocomplete
                          getOptionLabel={(option) => `${option.value} `}
                          options={severityList}
                          onChange={(e, value) => {generateReferenceCode();
                            if (value !== null) {
                              setTicketSeverity(value.code);
                            } else {
                              setTicketSeverity(null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Gravité *"
                              name="gravity"
                              variant="outlined"
                              style={{ width: '80%', marginLeft:  type === "ticket" ? '10%' : '20%' }}
                            />
                          )}
                        /></div>

                      </row>
                      <row style={{ marginTop: '20px', display: 'flex', marginBottom: '10px' }}>
                        <div style={{ width: '80%' }}>
                        <TextField multiline rows={5} label="Description * " placeholder="Description d'incident" style={{ width: '100%' }} onChange={(e) => {  handleTicketDescription(e);
                               const numberOfSpaces = e.target.value.split(' ').length - 1;
                               setSpaceCounter(numberOfSpaces); 
                            if (e.nativeEvent.data === ' ') {
                            //  console.log(e.nativeEvent.data);
                             if (spaceCounter >= 2) {getSuggestions();}} 
                              if (e.target.value === '' || e.target.value === null) {setSpaceCounter(0);}}} />
                        </div>
                      </row>
                      <div style={{ width: '80%'}}>
                          <TicketFileDropzone
                          onDrop={handleDrop}
                          maxFiles={5} />
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {fileValues.map((file, index) => (
                              <Card style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
                                <UploadFileIcon />
                                {file.name}
                                <IconButton onClick={() =>  handleFileDelete(index)}>
                                  <CloseIcon />
                                </IconButton>
                              </Card>
                            ))}
                          </div>

 
                      <div>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={ticketAcceptTracking}
                                onChange={() => {
                                  // setActivateFolliwTicketStatus(!activateFolliwTicketStatus);
                                   setTicketAcceptTracking(!ticketAcceptTracking); console.log(ticketAcceptTracking); console.log(!activateFolliwTicketStatus);}}
                              />
                            }
                            label={type === 'ticket' ? "Suivi du ticket par mail " : "Suivi de la réclamation par mail "}
                          />
                        </FormGroup>
                        
                        <h4 style={{color: theme.palette.primary.main}}> Commentaires :</h4>
                        <div style={{ width: '95%' }}>
                          <Box sx={{ alignItems: 'center', display: 'flex' }}>
                            <Avatar
                              sx={{ mr: 2 }}
                              src={user.avatar}
                            />
                            <TextField
                              fullWidth
                              onChange={handleTicketCommentChange}
                              placeholder="Ajouter un commentaire..."
                              size="small"
                              variant="outlined"
                              multiline
                              rows={2}
                            />
                          </Box>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      minWidth="25%"
                      // width="30%"
                      style={{ borderLeft: '2px solid' }}
                    >
                       <row style={{display: 'flex', justifyContent: 'center'}}>
                      <Typography style={{ textAlign: 'center', fontSize: '18px', color: theme.palette.primary.main, fontWeight: 'bold' }}>  Suggestions </Typography>
                      {/* <IconButton 
                      style={{paddingTop: '0px', marginLeft: '10px', padding: '0px'}}
                      onClick={getSuggestions}>
                          <RefreshIcon />
                      </IconButton> */}
                    </row>
                   {load === false ? (<div style={{ maxHeight: '250px', height: '55%', overflowY: 'auto', width: '100%', marginBottom: '0px', marginLeft: '5px'}}>
                          {AIOptions?.sort((a, b) => b.pertinence - a.pertinence).map((options, index) => {
                          let buttonContent;
                          if (ticketSolution === options.solution) {
                            buttonContent = <span style={{ color: 'green', margin: '0px', padding: '0px' }}>problème résolu</span>;
                          } else {
                            buttonContent = <p style={{ margin: '0px', padding: '0px' }}>problème résolu</p>;
                          }
                          return (
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '70%' }}>
                                <p style={{ fontSize: '12px' }}>{options.solution}</p>
                              </div>
                              <Button
                                style={{ marginLeft: '20px', fontSize: '12px' }}
                                onClick={() => { setSuggestionId(options.id); setSuggestion(options.solution); setTicketSolution(options.solution); setAiConfirm(true); setAiNotSolvedButtonColor('primary'); setTicketState('Résolu'); }}
                              >
                                {buttonContent}
                              </Button>
                            </div>
                          );
                        })}
                      </div>) : (
                          load === true ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45%' }}>
                              <CircularProgress />
                            </div>
                          ) : (
                              <div style={{height: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p>Aucune suggestion</p>
                              </div>
                          )
                         ) }
                      <Divider style={{ marginTop: '10px' }} />
                      <Typography style={{ textAlign: 'center', fontSize: '18px', marginTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold'}}>
                        Action corrective
                      </Typography>
                      <div style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                        <TextField
                          multiline
                          placeholder="Choisissez l&apos;action corrective d&apos;IA qui a résolu le problème ou proposez la vôtre ici."
                          rows={4}
                          value={ticketSolution}
                          style={{ width: '100%', marginTop: '6px', marginBottom: '6px' }}
                          onChange={(e) => { setTicketSolution(e.target.value);
                             if (e.target.value !== null && e.target.value !== '') { setTicketState('Résolu'); }
                             if (e.target.value !== suggestion) { setSuggestionId(null); setSuggestion(null); }
                             if (e.target.value === '') { setTicketState('Ouvert'); }
                           
                             }}
                        />
                      </div>
                      {/* <Divider style={{ marginBottom: '5px' }} /> */}
                      {/* <Button  style={{ marginRight: '10px', fontSize: '14px', float: 'right', padding: '0px', marginTop: '0px', marginBottom: '0px' }}  onClick={() => { setTicketSolution(''); setAiConfirm(true); setAiNotSolvedButtonColor('green'); setTicketState('Ouvert'); setSuggestionId(null); }}> <p style={{ color: aiNotSolvedButtonColor, padding: '0px', margin: '5px' }}>Problème non résolu </p></Button> */}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
              <p style={{margin: '0px', padding: '0px', marginTop: '10px', position: 'absolute', left: '10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>(*) Champ obligatoire </p>
              <Button
  onClick={() => {
    if (ticketDescription && ticketSeverity && ticketCategory) {
      handleShow();
      Swal.fire({
        title: type === 'ticket' ? "<h5>Vous risquez de perdre les informations de ce ticket, cliquez sur Suspendre le ticket pour le sauvegarder comme suspendu</h5>" : 
       "<h5>Vous risquez de perdre les informations de cette réclamation , cliquez sur Suspendre le ticket pour le sauvegarder comme suspendu</h5>" ,
        icon: "question",
        iconHtml: "!",
        confirmButtonText: type === 'ticket' ? "Suspendre le ticket" :  "Suspendre la réclamation",
        cancelButtonText: type === 'ticket' ? "Annuler le ticket" : "Annuler la réclamation",
        confirmButtonColor: theme.palette.primary.main,
        // denyButtonText: 'Annuler le ticket',
        showCancelButton: true,
        showCloseButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await ticketApi.addLearnerTicket(localStorage.getItem('accessToken'), ticketService, ticketCategory, ticketSeverity, ticketReferentId, ticketDescription, 0, null, parseInt(customer, 10), user.id, ticketReference, "Suspendu", ticketComment, type);
        if (type === 'ticket') {  toast.success('Ticket ajouté avec succès!');} else { toast.success('Réclamation ajouté avec succès!');}
          } catch (err) {
            console.log(err);
            toast.error('Il y a eu un souci lors de la création !');
          }
          setUpdate(!update);
          handleClose();
        } else {
          handleClose();
         {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
        }
      });
    } else {
      handleClose();
      {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
    }
  }}
  color="primary"
>
  Annuler
</Button>


                <div title="Veuillez compléter les champs obligatoires ( * ) ">
                  <Button
                    onClick={handleAdd}
                    color="primary"
                    type="submit"     
                    // title=""
                    disabled={
                      !(type !== 'ticket' || (type === 'ticket' && ticketService)) || // Checks if it's not a ticket type or it is a ticket type and service is provided
                      !ticketSeverity || // Checks if ticketSeverity is provided
                      !ticketDescription || ticketDescription === '' || // Checks if ticketDescription is provided
                      !ticketCategory // Checks if ticketCategory is provided
                    }
                  >
                 {type === 'ticket' ? 'Créer le ticket' : 'Créer la réclamation'}
                  </Button>
                </div>              
              </DialogActions>
            </Dialog>
            {/* )} */}
        {/* </Grid>
      )} */}
    </ThemeProvider>
  );
};
export default CustomerQualityTicketCreate;
CustomerQualityTicketCreate.propTypes = {
  customer: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
