/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker, LocalizationProvider } from '@material-ui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ticketApi } from '../../../api/ticketApi';
import toast from 'react-hot-toast';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import "moment/locale/fr";
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { createCustomTheme } from '../../../theme';
import useSettings from '../../../hooks/useSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEMES } from '../../../constants';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TextField,
  Switch,
  Avatar,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  Typography,
  Autocomplete,
  Box,
  Tabs,
  Tab, Card, IconButton, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import CustomerQualityComment from './QualityTicketComments';
import TicketFileDropzone from './TicketFileDropZone';

const tabs = [
  { label: 'Commentaires', value: 'comments' },
  { label: 'Historique', value: 'Log' },

];

const severityList = [
    {
      code: '0',
      value: 'Mineur'
    },
    {
      code: '1',
      value: 'Modéré'
    },
    {
      code: '2',
      value: 'Grave'
    },
    {
      code: '3',
      value: 'Critique '
    },
  ];

  
const TicketDialogManager = (props) => {
  const {
    show,type,
    handleClose,
    infoTicket,
    commentList,
    user,
    ticketComment,
    handleTicketCommentChange,
    handleKeyUp,
    setCommentList,
    setInfoTicket,
    setShow,
    setListServices,
    listServices,
    listCategories,
    listMembers,
    getCategories,
    getServiceMembers,
    setTicketService,
    setTicketCategory,
    setTicketReferentId,
    setTicketReferentName,
    setTicketSeverity,
    setTicketSolution,
    handleShow,
    ticketService,
    ticketSeverity,
    ticketDescription,
    ticketLog,
    ticketFiles,
    setTicketFiles,getTicketDocuments,
    ///for modifying
    setNewTicketService,
    setNewTicketSeverity,
    setNewTicketReferentId,
    setNewTicketTracking,
    setNewTicketSolution,
    setNewTicketState,
    setNewTicketMajorIncident,
    setNewTicketCategory,
    setNewTicketDRA,
    newTicketService,
    newTicketSeverity,
    newTicketReferentId,
    newTicketTracking,
    newTicketSolution,
    newTicketState,
    newTicketMajorIncident,
    newTicketCategory,
    newTicketDRA,
    setNewTicketAcceptTracking,
    newTicketAcceptTracking,
    newTicketDescription,
    setNewTicketDescription,
    //to compare
    setOldTicketService,
    setOldTicketSeverity,
    setOldTicketReferentId,
    setOldTicketTracking,
    setOldTicketSolution,
    setOldTicketState,
    setOldTicketMajorIncident,
    setOldTicketCategory,
    setOldTicketDRA,
    setOldTicketAcceptTracking,
    oldTicketDescription,
    setOldTicketDescription,
    oldTicketService,
    oldTicketSeverity,
    oldTicketReferentId,
    oldTicketTracking,
    oldTicketSolution,
    oldTicketState,
    oldTicketMajorIncident,
    oldTicketCategory,
    oldTicketDRA,
    oldTicketAcceptTracking,
    
    ///
    updateTicketInfo,
    update,
    setUpdate,
    validateTicket,
    openTicket,
    deleteTicket,
    majorIncident,
    getTickets,
    deleteTicketDocument
  } = props;

  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });


  const [currentTab, setCurrentTab] = useState('comments');
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };


  const [load, setLoad] = useState(null);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('primary');
  const [open, setOpen] = useState(false);
  const [fileValues, setFileValues] = useState([]);
  const [arrayTmp, setArrayTmp] = useState([]);
  const [AIOptions, setAIOptions] = useState([]); 
  const [problemId, setProblemId] = useState(null);
  const [suggestionId, setSuggestionId] = useState(null);
  const [suggestion, setSuggestion] = useState(null);

 
const [catIndex, setCatIndex] = useState(infoTicket.category_index);
const [memberIndex, setMemberIndex] = useState(infoTicket.referent_id);

const [showPJ, setShowPJ] = useState(false);
const [PJFile, setPJFile] = useState(null);
const handleclosePJ = () => {
  setShowPJ(false);
  setPJFile(null);
};

function deleteDocAlert(fileId,fileName) {

 if (type === 'ticket') { if (window.confirm(`Voulez-vous vraiment supprimer le document : ${fileName}  de ce ticket?`)) {
  deleteTicketDocument(fileId, infoTicket.ticketId);
    console.log("Supprimer");
    const index = ticketFiles.findIndex((pj) => pj.file_id === fileId);
    // ticketFiles.splice(index, 1);
  } } else {
    if (window.confirm(`Voulez-vous vraiment supprimer le document : ${fileName}  de cette réclamation?`)) {
    deleteTicketDocument(fileId, infoTicket.ticketId);
    console.log("Supprimer");
    const index = ticketFiles.findIndex((pj) => pj.file_id === fileId);
    // ticketFiles.splice(index, 1);
  }
  }
}
  const sendFeedback = async (prob, solution) => {
    try {
     const urlTicket = `${process.env.REACT_APP_API_IA}/api/updatepert`;
         const values = {};
         values.suggestion_id = prob;
         values.solution_id = solution;
         const resp = await fetch(urlTicket, {
           method: 'POST',
           body: JSON.stringify(values),
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
           }
         });
         const json = await resp.json();
   } catch {
     setLoad(null);
   }
 };

  const sendSolutionToAI = async (solution) => {
    try {
     const urlTicket = `${process.env.REACT_APP_API_IA}/api/addsolution`;
         const values = {};
         values.suggestion_id = problemId;
         values.suggestion_solution = solution;
         const resp = await fetch(urlTicket, {
           method: 'POST',
           body: JSON.stringify(values),
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
           }
         });
         const json = await resp.json();
   } catch {
     setLoad(null);
   }
 };
 const [spaceCounter, setSpaceCounter] = useState(0);
 const getSuggestions = async () => {
  if (ticketService === 2 && newTicketDescription) {
    setAIOptions(null);
setLoad(true);
    const timer = setTimeout(async () => {
try {
      const urlTicket = `${process.env.REACT_APP_API_IA}/api/suggestion`;
          const values = {};
          values.text = newTicketDescription;

          const resp = await fetch(urlTicket, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
            }
          });
          const json = await resp.json();
          setLoad(false);
          if (json.id) {
            setProblemId(json.id);
            setAIOptions(json.solutions);
          }
          else setLoad(null);
    } catch {
      setLoad(null);
    }
    }, 100);
  }
 };
 
  const handleCloseImportDialog = () => {
    setOpen(false);
    setFileValues([]);
    setArrayTmp([]);
  };
  
  const SaveFiles = () => {
    setOpen(false);
   
  };
  const handleSubmit = async (tiketId) => {
      await ticketApi.sendDoc(tiketId, user.id, fileValues, infoTicket.ref,  localStorage.getItem('accessToken'));
  };
 const handleChange = (index) => (event) => {
  event.preventDefault();
  const newArr = [...fileValues];
  newArr[index] = { ...newArr[index], [event.target.name]: event.target.value };
  setFileValues(newArr);
};

const handleFileDelete = (indexToDelete) => {
  setArrayTmp((prevArrayTmp) => {
    const updatedArrayTmp = [...prevArrayTmp];
    updatedArrayTmp.splice(indexToDelete, 1);
    return updatedArrayTmp;
  });
  setFileValues((prevFileValues) => {
    const updatedFileValues = [...prevFileValues];
    updatedFileValues.splice(indexToDelete, 1);
    return updatedFileValues;
  });
};

const handleDrop = async (acceptedFile) => {
// console.log(acceptedFile);
// console.log(arrayTmp.length + acceptedFile.length);
 if (arrayTmp.length + acceptedFile.length <= 5 && acceptedFile.length > 0) { acceptedFile.forEach((file) => {
  
    arrayTmp.push({ name: file.name, type: '', file });
  });
    setFileValues(arrayTmp);
   setOpen(true);
  } else {setOpen(false); toast.error("impossible d'importer plus que 5 fichiers !")}
};

  

  const serviceprops = {
    options: listServices,
    getOptionLabel: (option) => option.service_name
  };
  const [catprops, setCatProps] = useState({
    options: listCategories,
    getOptionLabel: (option) => option.category_title
  });
  const [memberprops, setMembreProps] = useState({
    options: listMembers,
    getOptionLabel: (option) => option.name
  });
  const severityprops = {
    options: severityList,
    getOptionLabel: (option) => option.value
  };
  
  const [category, setCategory] = useState(catprops.options[catprops.options.findIndex(cat => cat.category_id === catIndex) + 1]);
  const [member, setMember] = useState(memberprops.options[memberprops.options.findIndex(m => m.member_id === newTicketReferentId)]);
  const [oldcategory, setOldCategory] = useState(catprops.options[catprops.options.findIndex(cat => cat.category_id === catIndex) + 1]);
  const [oldmember, setoldMember] = useState(memberprops.options[memberprops.options.findIndex(m => m.member_id === oldTicketReferentId)]);
return (
<ThemeProvider theme={theme}>
        <Dialog onClose={handleclosePJ} open={showPJ} maxWidth="xl">
          <DialogTitle style={{display: "flex"}}>
            <row style={{display: 'flex'}}>
            <p style={{paddingRight: '60px', margin: '0px'}}>{PJFile?.file_name}</p>
       <div style={{marginTop: '5px', position: 'absolute', right: '30px', marginLeft: '10%'}}><a download href={PJFile?.link}><DownloadIcon color={theme.palette.primary.main} htmlColor={theme.palette.primary.main} /></a></div>
          </row></DialogTitle>
          <DialogContent>
            {PJFile?.extension === 'jpg' || PJFile?.extension === 'jfif' || PJFile?.extension === 'jpeg' || PJFile?.extension === 'png' || PJFile?.extension === 'gif' ? (<img src={PJFile?.link} height="700px" alt="test" style={{borderRadius: '6px'}} />) : null}
            {PJFile?.extension === 'mp4' || PJFile?.extension === 'webm' || PJFile?.extension === 'mkv' || PJFile?.extension === 'flv' || PJFile?.extension === 'vob' || PJFile?.extension === 'ogg' || PJFile?.extension === 'ogv' || PJFile?.extension === 'avi' || PJFile?.extension === 'gifv' || PJFile?.extension === 'mpeg' ? (<video controls width="600" height="600"><source src={PJFile?.link} type="video/mp4" /><source src={PJFile?.link} type="video/webm" /><source src={PJFile?.link} type="video/mkv" /><source src={PJFile?.link} type="video/flv" /><source src={PJFile?.link} type="video/vob" /><source src={PJFile?.link} type="video/ogg" /><source src={PJFile?.link} type="video/ogv" /><source src={PJFile?.link} type="video/gif" /><source src={PJFile?.link} type="video/gifv" /><source src={PJFile?.link} type="video/avi" /><source src={PJFile?.link} type="video/mpeg" />Error</video>) : null}
            {PJFile?.extension === 'pdf' ? (<object style={{width: '1000px'}} data={PJFile?.link} type="application/pdf" width="100%" height="500px">
              <p>Unable to display PDF file. <a href={PJFile?.link}>Download</a> instead.</p>
            </object>) : null}
          </DialogContent>
          <DialogActions>
         
          <Button color="primary"
onClick={handleclosePJ}>
            Fermer
            </Button>
        </DialogActions>
          </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseImportDialog}
      >
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fileValues.length <= 5 ? "Confirmer le nom et le type de chaque fichier à importer." : "Impossible d'importer plus que 5 fichiers"}
            
          </DialogContentText>
          {fileValues.map((value, index) => (
    <div style={{alignContent: 'center', alignItems: 'center', display: 'flex'}}>
      {index + 1}
      {'. '}
      <TextField
        variant="outlined"
        sx={{ m: '20px' }}
        name="name"
        label="Nom du fichier"
        onChange={handleChange(index)}
        value={value.name}
      />
      <IconButton onClick={() => handleFileDelete(index)}>
        <CloseIcon />
      </IconButton>
      <br />
    </div>
  ))
}
    
       </DialogContent>
        <DialogActions>
          {fileValues.length <= 5 ? 
        (<> <Button
            onClick={SaveFiles}
            color="primary"
          >
            Enregistrer
          </Button>
          <Button
            onClick={handleCloseImportDialog}
            color="primary"
          >
            Annuler
          </Button></>
        )  : (
          <Button color="primary"
onClick={handleCloseImportDialog}>
            Fermer
          </Button>
        )
        }
        </DialogActions>
      </Dialog>

            <Dialog
                onClose={handleShow}
                open={show}
                fullWidth="lg"
                maxWidth="lg"
            >
              <DialogTitle style={{overflowX: 'auto'}}>

              <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={11}
spacing={1}
                    >
                <row style={{ display: 'flex', padding: '0px' }}>           
                <div style={{ float: 'right', marginRight: '3%' }}>
                       {infoTicket.state === 'Ouvert' ? (
                          <p style={{fontSize: '15px', margin: '0px', color: 'white', backgroundColor: 'grey', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'Suspendu' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: '#f44336', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'Résolu' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: 'green', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'En cours' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: 'orange', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : null}
                      </div>       
                        <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Réf. :&#160;</p>
                        <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.ref}</p>
                        {infoTicket.creator_id !== null ? (
                          <>
                          {type === 'ticket' ? <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur du ticket :&#160;</p>
                          : <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur de la réclamation :&#160;</p>  }
                          <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.creator_name}</p>
                          </>
                          ) : null}
                        
                        

                        <p style={{ fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Date de création :&#160;</p> 
                        <p style={{ fontSize: '15px', margin: '0%', marginLeft: '5px', paddingTop: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>
                          {format(new Date(infoTicket.date_creation), "dd/MM/yyyy 'à' HH:mm")}
                         </p>
                         {infoTicket.learner !== null ? (
                          <>
                          <p style={{ fontSize: '15px', margin: '0px', marginLeft: '1.5%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Apprenant :&#160;</p> 
                          <p style={{ fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.learner}</p>
                        </>) : null }

                        {(infoTicket.state === 'Suspendu' && user.id === infoTicket.creator_id) || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1) ? (
                          <div style={{ margin: '0px', padding: '0px', position: 'absolute', right: '20px'}}
title="Supprimer le ticket">
                        <IconButton aria-label="delete"
size="large"
style={{padding: '0px', paddingBottom: '5px'}}
onClick={() => {
                          handleShow();
                          Swal.fire({
                            title: type === 'ticket' ? "<h5>Vous risquez de perdre les informations de ce ticket, Voulez-vous vraiment supprimer ce ticket de façon permanente?" :
                             "<h5>Vous risquez de perdre les informations de cette réclamation, Voulez-vous vraiment supprimer cette réclamation de façon permanente?",
                            icon: "warning",
                            iconHtml: "!",
                            confirmButtonText: "Supprimer le ticket",
                            cancelButtonText: "Annuler",
                            confirmButtonColor: theme.palette.primary.main,
                            // denyButtonText: 'Annuler le ticket',
                            showCancelButton: true,
                            showCloseButton: true
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                            await  deleteTicket(infoTicket.ticketId);
                              getTickets();
                              setUpdate(!update);
                              handleClose();
                            } else {
                              handleClose();
                            }
                          });
                       
                          }}>
                            <DeleteIcon color="error" />
                        </IconButton>
                                 </div> 
                        ) : null }
                          
                      </row>
                        </Grid>
                        
                        </Grid>
                     
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={9}
                    >
                      <row style={{ display: 'flex' }}>
                       {type === 'ticket' && <Autocomplete
                          {...serviceprops}
                          style={{ width: '25%' }}
                          getOptionLabel={(option) => `${option.service_name} `}
                          options={listServices}
                          disableClearable
                          defaultValue={serviceprops.options[infoTicket.service - 1]}
                          onChange={(e, value) => {
                            if (value !== null) {
  
                              if (value.service_id !== oldTicketService) {
                                setCategory(null);
                              setMember(null);
                              getCategories(value.service_id);
                              getServiceMembers(value.num_service);
                              setNewTicketService(value.service_id);
                              }
                              else {
                                 setCategory(oldcategory);
                              setMember(oldmember);
                              getCategories(value.service_id);
                              getServiceMembers(value.num_service);
                              }
                              
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Service *"
                              name="service"
                              variant="outlined"
                              style={{ width: '100%', marginLeft: '0%' }}
                            />
                          )}
                        />}
                        <Autocomplete
                          {...catprops}
                          style={{ width: '25%' }}
                          getOptionLabel={(option) => `${option.category_title} `}
                          defaultValue={category}
                          value={category}
                          disableClearable
                          options={listCategories}
                          // freeSolo
                          onChange={(e, value) => {
                            if (value !== null) {
                              setCategory(value);
                              setNewTicketCategory(value.category_id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Catégorie *"
                              name="Category"
                              variant="outlined"
                              style={{ width: '100%', marginLeft: type === 'ticket' ? '15%' : '0%' }}
                            />
                          )}
                        />
                        <Autocomplete
                          {...memberprops}
                          style={{ width: '30%' }}
                          getOptionLabel={(option) => `${option.name} `}defaultValue={member}
                          ptions={listMembers?.sort((a, b) => { const order = ["Direction", "Informatique", "Commercial", "Administratif"]; const indexA = order.indexOf(a.service_name);  const indexB = order.indexOf(b.service_name);
    if (indexA > indexB) { return 1;} if (indexA < indexB) { return -1;} return 0;})}
                          groupBy={(option) => option.service_name}
                          
                          value={member}
                          // freeSolo
                          onChange={(e, value) => {
                            if (value !== null) {
                              setMember(value);
                               setNewTicketReferentId(value.member_id);
                              setTicketReferentName(value.name);
                            }
                            else {
                              setMember(null);
                               setNewTicketReferentId(null);
                              setTicketReferentName(null);
                            }
                              
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Référent"
                              name="referent"
                              variant="outlined"
                              style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }}
                            />
                          )}
                        />
                        <Autocomplete
                          {...severityprops}
                          style={{ width: '25%' }}
                          getOptionLabel={(option) => `${option.value} `}
                          defaultValue={severityprops.options[parseInt(infoTicket.severity, 10)]}
                          options={severityList}
                          onChange={(e, value) => {
                            if (value !== null) {
                              setTicketSeverity(value.code);
                              ///modify
                              setNewTicketSeverity(value.code);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Gravité *"
                              name="gravity"
                              variant="outlined"
                              style={{ width: '80%', marginLeft: '10%' }}
                            />
                          )}
                        />
                      </row>
                        <row style={{ width: '90%', display: 'flex', marginBottom: '0px', paddingBottom: '0px' }}>
                          <h4 style={{ whiteSpace: 'nowrap', marginBottom: '0px', color: theme.palette.primary.main }}> Description * :</h4>  
                          { (user.id === infoTicket.creator_id && infoTicket.state === 'Suspendu') || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1) ? (
                            <TextField multiline
rows={3}
placeholder="Description d'incident"
style={{ width: '72%', marginTop: '22px', marginLeft: '1%' }}
value={newTicketDescription}
onChange={(e) => {setNewTicketDescription(e.target.value); 
  const numberOfSpaces = e.target.value.split(' ').length - 1;
  setSpaceCounter(numberOfSpaces); 
if (e.nativeEvent.data === ' ') {
// console.log(e.nativeEvent.data);
if (spaceCounter >= 2) {getSuggestions();}} 
 if (e.target.value === '' || e.target.value === null) {setSpaceCounter(0);}}} /> /// add on change
                          ) : (
                            <Typography style={{marginTop: '22px', marginLeft: '1%', marginBottom: '0px', maxHeight: '200px', overflowX: 'auto', color: theme.palette.dialogText.main }}>{infoTicket.description}</Typography>
                          )
                          }
                        </row>
                        <row style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0px', paddingTop: '15px' }}>
                         <h4 style={{paddingTop: '6px', color: theme.palette.primary.main}}> Pièces jointes :</h4>
                         <div style={{maxHeight: '90px', overflowY: 'auto', width: '80%', flexWrap: 'wrap', display: 'flex'}}>
                          {ticketFiles?.map((file) => (
                            <>
                            {file.extension === 'jpg' || file.extension === 'jfif' || file.extension === 'jpeg' || file.extension === 'png' 
                            || file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                            || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv' || file.extension ===  'gif' 
                            || file.extension === 'avi' || file.extension === 'gifv' || file.extension === 'mpeg' || file.extension === 'pdf' ? 
                            (
                               <Card 
                              sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }}

                              style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}

                              title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `}>

                              <Box style={{display: 'flex'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>

                              {file.extension === 'pdf' ? (
                              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png" 
                              
                              height="40px" width="40px" alt="test"
                              
                              style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />)

                              : file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                              || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv'  || file.extension === 'avi' 
                              || file.extension === 'gifv' || file.extension === 'mpeg' ? (
                                <PlayArrowIcon style={{ marginRight: '10px', margin: 'auto'}} />
                              ) : (file.extension ===  'gif' || file.extension === 'jpg' ||  file.extension === 'jfif' || file.extension === 'jpeg' 
                              || file.extension === 'png' ? (<img src={file.link} height="40px" width="40px" alt="test" style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />) : null)}
                              &nbsp;
                              <p style={{textOverflow: 'ellipsis'}}>{file.file_name}</p></Box>
                              <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}>
                              <DeleteIcon style={{color: theme.palette.primary.main}} />
                              </IconButton>
                               </Card>
                            ) : (
                           
                            <Card sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }} 
                            style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
                            title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `}>
                           <a href={file.link} download rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>     <Box style={{display: 'flex', margin: 'auto'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>
                            
                              <InsertDriveFileIcon fontSize="large" style={{margin: 'auto'}} />
                              &nbsp;
                              <p style={{textOverflow: 'ellipsis'}}>{file.file_name}</p>
                              </Box></a>
                              <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}>
                              <DeleteIcon style={{color: theme.palette.primary.main}} />
                              </IconButton>
                               </Card>) }
                         </> 
                         
                         ))}</div> 
                        </row>
                        <div style={{ width: '76%', marginTop: '5px'}}>
                          <TicketFileDropzone
                          onDrop={handleDrop}
                          maxFiles={5} />
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0px', margin: '0px', maxHeight: '76px', overflowY: 'auto', width: '95%', marginBottom: '0px' }}>
                            {fileValues.map((file, index) => (
                            <Card style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
                                <UploadFileIcon />
                                {file.name}
                                <IconButton onClick={() =>  handleFileDelete(index)}>
                                  <CloseIcon />
                                </IconButton>            
                              </Card>
                           ))}
                          </div>
                          {infoTicket.learnerId &&     <row style={{ display: 'flex', margin: '0px', marginTop: '15px' }}>
                      {infoTicket.type === 'ticket' ?  <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi du ticket :&#160;</p>
                      : <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi de la réclamation :&#160;</p>}
                      <Switch checked={newTicketAcceptTracking} onChange={() => { if (infoTicket.state === "Ouvert" || infoTicket.state === "Suspendu") {
                        if ((user.id === infoTicket.creator_id) || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1)) {setNewTicketAcceptTracking(!newTicketAcceptTracking);
                         }}}} />
                      </row>
                          }
                  
                      <div>
                        <Tabs 
                        indicatorColor="primary"
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={currentTab}
                        variant="scrollable">
                       { tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              )) }
                        </Tabs>
                        {currentTab === 'comments' && (
                           <div style={{width: '95%', marginTop: '10px', minHeight: commentList.length === 0 ? "30px" : "120px", maxHeight: commentList.length === 0 ? "40px" : "120px", marginBottom: '40px' }}>
                              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                <Avatar sx={{ mr: 2 }}
src={user.avatar} />  
                                <TextField 
                                  style={{ width: '90%' }} 
                                  fullWidth
onChange={handleTicketCommentChange}
placeholder="Ajouter un commentaire..."
size="small"
value={ticketComment}
variant="outlined"
multiline
                                  onKeyUp={handleKeyUp}
                                />
                              </Box>
                              {commentList.length !== 0 ? (
                                <div style={{ width: '100%', maxHeight: commentList.length === 0 ? "90px" : "116px", overflowY: 'auto', marginTop: '10px' }}>
                                {commentList.slice().reverse().map((comment) => (<CustomerQualityComment comment={comment} />))}
                              </div>
                              ) : ( 
                                <div style={{ width: '100%', maxHeight: commentList.length === 0 ? "80px" : "106px", overflowY: 'auto', marginTop: '0px' }}>
                               <p style={{ textAlign: 'center', width: '100%'}}> Aucun commentaire pour le moment </p>
                              </div>
                              )}
                              
                            </div>
                        )}
   {currentTab === 'Log' && (
  <div style={{maxHeight: commentList.length === 0 ? "100px" : "130px", width: '100%', overflowY: 'auto', minHeight: commentList.length === 0 ? "40px" : "130px", marginBottom: '40px' }}>
    {ticketLog.map((event) => (
      <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
        <div style={{ marginRight: '2%'}}>
        <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', color: theme.palette.dialogText.main,  whiteSpace: 'nowrap'}}> {format(new Date(event.time), "dd/MM/yyyy 'à' HH:mm:ss")}</h5>
        </div>
        <div style={{marginRight: '1%'}}> 
        <a style={{textDecoration: 'none', decoration: 'none'}} href={`${process.env.REACT_APP_API}/dashboard/user/${event.user_id}`} target="_blank>"><h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main }}>{event.user_name}</h5></a>
        </div>
        <div style={{ width: '70%', marginRight: '1%', marginTop: '2px'}}>
          <p style={{ marginRight: '10px', padding: '0px', margin: '0px', color: theme.palette.dialogText.main }}>{event.description}</p>
        </div>
        
      </div>
    ))}
  </div>
)}

                      </div>
                    </Grid>
                    {infoTicket.state === "Suspendu" ? (<Grid
                      item
                      xs={3}
                      minWidth="25%"
                      // width="30%"
                      style={{ borderLeft: '2px solid' }}
                    >
                       <row style={{display: 'flex', justifyContent: 'center'}}>
                      <Typography style={{ textAlign: 'center', fontSize: '18px' }}>  Suggestions </Typography>
                    </row>
                   {load === false ? (<div style={{ maxHeight: '250px', height: '55%', overflowY: 'auto', width: '100%', marginBottom: '0px', marginLeft: '5px'}}>
                          {AIOptions?.sort((a, b) => b.pertinence - a.pertinence).map((options, index) => {
                          let buttonContent;
                          if (newTicketSolution === options.solution) {
                            buttonContent = <span style={{ color: 'green', margin: '0px', padding: '0px' }}>problème résolu</span>;
                          } else {
                            buttonContent = <p style={{ margin: '0px', padding: '0px' }}>problème résolu</p>;
                          }
                          return (
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '70%' }}>
                                <p style={{ fontSize: '12px' }}>{options.solution}</p>
                              </div>
                              <Button
                                style={{ marginLeft: '20px', fontSize: '12px' }}
                                onClick={() => { setSuggestionId(options.id); setSuggestion(options.solution); setNewTicketSolution(options.solution);  setAiNotSolvedButtonColor('primary'); setNewTicketState('Résolu'); }}
                              >
                                {buttonContent}
                              </Button>
                            </div>
                          );
                        })}
                      </div>) : (
                          load === true ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45%' }}>
                              <CircularProgress />
                            </div>
                          ) : (
                              <div style={{height: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p>Aucune suggestion</p>
                              </div>
                          )
                         ) }
                      <Divider style={{ marginTop: '10px' }} />
                      <Typography style={{ textAlign: 'center', fontSize: '18px', marginTop: '5px' }}>
                        Actions correctives
                      </Typography>
                      <div style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                      {infoTicket.solution_major_incident ? (
                           <Accordion style={{width: '100%', marginBottom: '20px'}}>
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon />}
                             aria-controls="panel1a-content"
                             id="panel1a-header"
                           >
                             <Typography color="primary" style={{fontWeight: 'bold'}}>Incident majeur : MI {majorIncident.incident_id}</Typography>
                           </AccordionSummary>
                           <div style={{maxHeight: '150px', overflowY: 'auto', padding: '10px', width: '100%'}}>
                           <AccordionDetails style={{margin: '0px', padding: '0px'}}>
                             <row style={{display: 'flex'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de début :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{format(new Date(majorIncident.start_date), "dd/MM/yyyy à HH:mm")}</p>
                             </row>
                             
                             <row style={{display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de fin :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.end_date ? (format(new Date(majorIncident.end_date), "dd/MM/yyyy à HH:mm")) : "-" }</p>
                             </row>
                   
                             <row style={{display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Créateur :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.creator_name}</p>
                             </row>
                   
                             <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Description d&apos;incident :  </Typography>
                             <p style={{padding: '0px', margin: '0px'}}>{majorIncident.description}</p>
                             </row>
                   
                             <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Action corrective :  </Typography>
                             <p style={{marginLeft: '10px', padding: '0px', margin: '0px'}}>{majorIncident.solution}</p>
                             </row>
                           </AccordionDetails></div>
                         </Accordion>) : null }
                        <TextField
                          multiline
                          placeholder="Choisissez la solution d&apos;IA qui a résolu le problème ou proposez la vôtre ici."
                          rows={4}
                          value={newTicketSolution}
                          style={{ width: '100%', marginTop: '6px', marginBottom: '6px' }}
                          onChange={(e) => { setNewTicketSolution(e.target.value);
                             if (e.target.value !== null && e.target.value !== '') { setNewTicketState('Résolu'); }
                             if (e.target.value !== suggestion) { setSuggestionId(null); setSuggestion(null); }
                             }}
                        />
                      </div>
                    </Grid>) :
                    (
                      <Grid
                      item
                      xs={3}
                      minWidth="20%"
                      // width="30%"
                      style={{ borderLeft: '2px solid' }}
                    >
                      <div style={{ width: '100%', marginTop: '0px', marginBottom: '15px' }}>
                      {infoTicket.solution_major_incident ? (
                           <Accordion style={{width: '100%', marginBottom: '20px', }}>
                            <AccordionSummary
                             expandIcon={<ExpandMoreIcon />}
                             aria-controls="panel1a-content"
                             id="panel1a-header"
                           >
                             <Typography color="primary" style={{fontWeight: 'bold'}}>Incident majeur : MI {majorIncident.incident_id}</Typography>
                           </AccordionSummary>
                           <div style={{maxHeight: '160px', overflowY: 'auto', padding: '10px', width: '100%'}}>
                           <AccordionDetails style={{margin: '0px', padding: '0px'}}>
                           
                             <row style={{display: 'flex'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de début :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{format(new Date(majorIncident.start_date), "dd/MM/yyyy à HH:mm")}</p>
                             </row>
                             
                             <row style={{display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de fin :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.end_date ? (format(new Date(majorIncident.end_date), "dd/MM/yyyy à HH:mm")) : "-" }</p>
                             </row>
                   
                             <row style={{display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Créateur :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.creator_name}</p>
                             </row>
                   
                             <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px', alignItems: 'flex-start'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold'}}> Description d&apos;incident :  </Typography>
                             <p style={{padding: '0px', margin: '0px', whiteSpace: 'pre-wrap', }}>{majorIncident.description}</p>
                             </row>
                   
                             <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                             <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Action Corrective :  </Typography>
                             <p style={{padding: '0px', margin: '0px', marginLeft: '10px'}}>{majorIncident.solution}</p>
                             </row>
                            
                           </AccordionDetails>
                       </div>   </Accordion>) : null }
                        <TextField multiline
                          label={!infoTicket.solution_major_incident ? "Actions correctives" : "Actions correctives spécifique au ticket"}
                          placeholder=""
                          rows={3}
                          value={newTicketSolution}
                          style={{ width: '100%', height: '95%', marginTop: '0px', marginBottom: '0px' }}
                          onChange={(e) => { setTicketSolution(e.target.value); setNewTicketSolution(e.target.value); if (newTicketSolution !== null && newTicketSolution !== '') { setNewTicketState('Résolu'); } }} />
                      </div>
                      <Divider style={{  marginBottom: '5%', }} />
                      <div>
           
                      {infoTicket.date_take_charge !== null ? (
                            <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}>
                            <MobileDateTimePicker 
                             ampm={false}
                             cancelText="Annuler"
                             todayText="Aujourd'hui"
                             showTodayButton
                            locale={fr}
                            sx={{ padding: 0, margin: 0 }}
                            //  fullWidth
                            inputFormat="DD/MM/YYYY à HH:mm"
                             readOnly
                             disabled
                             label="Date de prise en charge : "
                            name="dateprisecharge"
                            renderInput={(inputProps) => (
                            <TextField
                            style={{ padding: '0px', margin: '0px' }}
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                            )}
                            value={infoTicket.date_take_charge}
                          /></LocalizationProvider></div>
                      
                          ) : (
                            <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}>
                            <MobileDateTimePicker 
                             ampm={false}
                             cancelText="Annuler"
                             showTodayButton
                             todayText="Aujourd'hui"
                            sx={{ padding: 0, margin: 0 }}
                            //  fullWidth
                            inputFormat="DD/MM/YYYY à HH:mm"
                             readOnly
                             disabled
                             label="Date de prise en charge : "
                            name="dateprisecharge"
                            renderInput={(inputProps) => (
                            <TextField
                            style={{ padding: '0px', margin: '0px' }}
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                            )}
                            value={null}
                          /></LocalizationProvider></div>
                          ) 
                          }
      
                          {infoTicket.date_res_approx !== null ? (
                            <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                                <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="dd/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                 disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de résolution approximative "
                                name="dateprisecharge"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={newTicketDRA}
                              /></LocalizationProvider>
                            </div>
                          ) : ( 
                            <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                               <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              cancelText="Annuler"
                              todayText="Aujourd'hui"
                              showTodayButton
                              okText="OK"
                                sx={{ padding: 0, margin: 0 }}
                                 fullWidth
                                inputFormat="DD/MM/YYYY à HH:mm"
                                //  readOnly
                                //  disabled
                                onChange={(e) => {
                                  setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de résolution approximative "
                                name="dateprisecharge"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={
                                  newTicketDRA}
                              /></LocalizationProvider>
                            </div>)}
                        
                        
                        
                            {infoTicket.date_res_eff !== null ? (
                             <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}> 
                            <MobileDateTimePicker 
                             ampm={false}
                             cancelText="Annuler"
                             todayText="Aujourd'hui"
                             showTodayButton
                            sx={{ padding: 0, margin: 0 }}
                            //  fullWidth
                            inputFormat="dd/MM/yyyy à HH:mm"
                             readOnly
                             disabled
                             label="Date de résolution effective "
                            name="dateprisecharge"
                            renderInput={(inputProps) => (
                            <TextField
                            style={{ padding: '0px', margin: '0px' }}
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                            )}
                            value={infoTicket.date_res_eff}
                          />
                        </div>
                          ) : (
                            <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}> 
                           <MobileDateTimePicker 
                           ampm={false}
                           cancelText="Annuler"
                           todayText="Aujourd'hui"
                           showTodayButton
                           sx={{ padding: 0, margin: 0 }}
                           //  fullWidth
                           inputFormat="dd/MM/yyyy à HH:mm"
                            readOnly
                            disabled
                            label="Date de résolution effective "
                           name="dateprisecharge"
                           renderInput={(inputProps) => (
                           <TextField
                           style={{ padding: '0px', margin: '0px' }}
                             fullWidth
                             variant="outlined"
                             {...inputProps}
                           />
                           )}
                           value={null}
                         />
                       </div>
                         )  }
                   
                      </div>

                    </Grid>
                    ) 
                }
                    
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
              <p style={{margin: '0px', padding: '0px', marginTop: '10px', position: 'absolute', left: '10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>(*) Champ obligatoire </p>
              <Button
                  onClick={() => {
                    handleClose(); handleShow();
                  }}
                  color="primary"
              >
                  Fermer
              </Button>
              {  newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId || newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||  newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription || fileValues.length !== 0  ? (
                    <Button
                      onClick={() => {
                        if (suggestionId !== null) {
                          sendFeedback(problemId, suggestionId);
                        }
                        if (suggestion === null && newTicketSolution !== null) {
                          sendSolutionToAI(newTicketSolution);
                        }
                        handleSubmit(infoTicket.ticketId);
                         updateTicketInfo(user.id, infoTicket.ticketId);
                         handleShow();
                        handleClose();
                        getTickets();
                        setUpdate(!update);               
                      }}
                      color="primary"
                      // disabled={!(newTicketService !== null && newTicketService !== '' && newTicketSeverity !== null && newTicketSeverity !== '' && newTicketSeverity !== null && newTicketSeverity !== '')}
                    >
                      Enregistrer les modifications
                    </Button>
                  ) : (
                    ((infoTicket.state === 'Ouvert' && infoTicket.referent_id === user.id) || (infoTicket.state === 'Ouvert' && user.id_service === 0 && user.access_level === 0) || (infoTicket.state === 'Ouvert' && user.service_manager === 1)) ? (
                      <Button
                        onClick={() => {
                          getTickets();
                          setUpdate(!update);
                          handleClose();
                          handleShow();
                          handleSubmit(infoTicket.ticketId);
                          if (newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId ||
                            newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||
                            newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription 
                        ) {
                         updateTicketInfo(user.id, infoTicket.ticketId);
                      }
                          validateTicket(infoTicket.ticketId);
                        }}
                        color="primary"
                      >
                        Valider
                      </Button>
                    )  : null
                  )
                } 
                 {((infoTicket.state === 'Suspendu' && infoTicket.creator_id === user.id) || (infoTicket.state === 'Suspendu' && user.id_service === 0 && user.access_level === 0) || (infoTicket.state === 'Suspendu' && user.service_manager === 1)) ? (
                      <Button
                        onClick={() => {
                          getTickets();
                          setUpdate(!update);
                          handleClose();
                          handleShow();
                          handleSubmit(infoTicket.ticketId);
                          if (newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId ||
                              newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||
                              newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription 
                          ) {
                           updateTicketInfo(user.id, infoTicket.ticketId);
                        }
                          openTicket(infoTicket.ticketId);
                        }}
                        color="primary"
                      >
                        {type === 'ticket' ? 'Ouvrir le ticket' : 'Ouvrir la réclamation'}
                      </Button>
                    ) : null}         
              </DialogActions>
            </Dialog>
</ThemeProvider>
); };
TicketDialogManager.propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    infoTicket: PropTypes.object.isRequired,
    commentList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    ticketComment: PropTypes.string,
    handleTicketCommentChange: PropTypes.func.isRequired,
    handleKeyUp: PropTypes.func.isRequired,
    setCommentList: PropTypes.func.isRequired,
    setInfoTicket: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    setListServices: PropTypes.func.isRequired,
    listServices: PropTypes.array.isRequired,
    listCategories: PropTypes.array.isRequired,
    listMembers: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    getServiceMembers: PropTypes.func.isRequired,
    setTicketService: PropTypes.func.isRequired,
    setTicketCategory: PropTypes.func.isRequired,
    setTicketReferentId: PropTypes.func.isRequired,
    setTicketReferentName: PropTypes.func.isRequired,
    setTicketSeverity: PropTypes.func.isRequired,
    setTicketSolution: PropTypes.func.isRequired,
    handleShow: PropTypes.func.isRequired,
    ticketService: PropTypes.number.isRequired,
    ticketSeverity: PropTypes.number.isRequired,
    ticketDescription: PropTypes.string.isRequired,
    ticketLog: PropTypes.array.isRequired,
    ///for modifying
    setNewTicketService: PropTypes.func.isRequired,
    setNewTicketSeverity: PropTypes.func.isRequired,
    setNewTicketReferentId: PropTypes.func.isRequired,
    setNewTicketTracking: PropTypes.func.isRequired,
    setNewTicketSolution: PropTypes.func.isRequired,
    setNewTicketState: PropTypes.func.isRequired,
    setNewTicketMajorIncident: PropTypes.func.isRequired,
    setNewTicketCategory: PropTypes.func.isRequired,
    setNewTicketDRA: PropTypes.func.isRequired,
    newTicketService: PropTypes.number.isRequired,
    newTicketSeverity: PropTypes.number.isRequired,
    newTicketReferentId: PropTypes.number.isRequired,
    newTicketTracking: PropTypes.number.isRequired,
    newTicketSolution: PropTypes.string.isRequired,
    newTicketState: PropTypes.string.isRequired,
    newTicketMajorIncident: PropTypes.number.isRequired,
    newTicketCategory: PropTypes.number.isRequired,
    newTicketDRA: PropTypes.instanceOf(Date).isRequired,
    setNewTicketAcceptTracking: PropTypes.func.isRequired,
    newTicketAcceptTracking: PropTypes.bool.isRequired,
    newTicketDescription: PropTypes.string.isRequired,
    setNewTicketDescription: PropTypes.func.isRequired,
    ////////////////to compare
    setOldTicketService: PropTypes.func.isRequired,
    setOldTicketSeverity: PropTypes.func.isRequired,
    setOldTicketReferentId: PropTypes.func.isRequired,
    setOldTicketTracking: PropTypes.func.isRequired,
    setOldTicketSolution: PropTypes.func.isRequired,
    setOldTicketState: PropTypes.func.isRequired,
    setOldTicketMajorIncident: PropTypes.func.isRequired,
    setOldTicketCategory: PropTypes.func.isRequired,
    setOldTicketDRA: PropTypes.func.isRequired,
    setOldTicketAcceptTracking: PropTypes.func.isRequired,
    oldTicketService: PropTypes.number.isRequired,
    oldTicketSeverity: PropTypes.number.isRequired,
    oldTicketReferentId: PropTypes.number.isRequired,
    oldTicketTracking: PropTypes.number.isRequired,
    oldTicketSolution: PropTypes.string.isRequired,
    oldTicketState: PropTypes.string.isRequired,
    oldTicketMajorIncident: PropTypes.number.isRequired,
    oldTicketCategory: PropTypes.number.isRequired,
    oldTicketDRA: PropTypes.instanceOf(Date).isRequired,
    oldTicketAcceptTracking: PropTypes.bool.isRequired,
    oldTicketDescription: PropTypes.string.isRequired,
    setOldTicketDescription: PropTypes.func.isRequired,
    updateTicketInfo: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired,
    setUpdate: PropTypes.func.isRequired,
    ticketFiles: PropTypes.array.isRequired,
    setTicketFiles: PropTypes.func.isRequired,
    validateTicket: PropTypes.func.isRequired,
    openTicket: PropTypes.func.isRequired,
    deleteTicket: PropTypes.func.isRequired,
    majorIncident: PropTypes.object.isRequired,
    getTickets: PropTypes.func.isRequired,
    deleteTicketDocument: PropTypes.func.isRequired,
    getTicketDocuments: PropTypes.func.isRequired,
  };
  
  export default TicketDialogManager;
