import { Box, Button, Card, Link, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, CardActions, Typography } from '@material-ui/core';
import { dashboardApi } from '../../../api/dashboardApi';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import useAuth from '../../../hooks/useAuth';
import * as moment from 'moment';

const applyPagination = (customers) => customers
  .slice();

const FinanceIncrementalSales = () => {
  const [customers, setCustomers] = useState([]);
  const { user } = useAuth();
  const mounted = useMounted();
  const [selectedCustomers] = useState([]);
  const filteredCustomers = (customers);
  const sortedCustomers = (filteredCustomers);
  const paginatedCustomers = applyPagination(sortedCustomers);

  const getList = useCallback(async () => {
    try {
      const data = await dashboardApi.getList(localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setCustomers(data.liste);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getList();
  }, [getList]);
  return (
    <Card>
      <CardHeader title={user.id_service !== 4 ? 'Derniers clients' : 'Derniers terminés'} />
      <Box sx={{ minWidth: 700 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Nom et Email
              </TableCell>
              {user.id_service !== 4 && (
              <TableCell>
                Date
              </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/dashboard/customers/${customer.customers_id}`}
                          variant="subtitle2"
                        >
                          {customer.firstname}
                          {' '}
                          {customer.lastname}
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {customer.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {user.id_service !== 4 && (
                  <TableCell
                    color="textSecondary"
                    variant="body2"
                  >
                    {moment(customer.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {user.id_service !== 4 && (
      <CardActions>
        <Button
          color="primary"
          variant="text"
          component={RouterLink}
          to="/dashboard/customers/"
        >
          Plus de clients
        </Button>
      </CardActions>
      )}
    </Card>
  );
};

export default FinanceIncrementalSales;
