/* eslint-disable no-unused-vars */
import { Box, Card, Link, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, Grid } from '@material-ui/core';
import { dashboardApi } from '../../../api/dashboardApi';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import * as moment from 'moment';
import { Formik } from 'formik';
import { MobileDatePicker } from '@material-ui/lab';
import * as Yup from 'yup';

const applyPagination = (statut) => statut
  .slice();

const FinanceCustomersRecalls = () => {
  const [commentsCommercial, setCommentsCommercial] = useState([]);
  const filteredCustomers = (commentsCommercial);
  const sortedCustomers = (filteredCustomers);
  const [selectedCustomers] = useState([]);
  const paginatedCustomers = applyPagination(sortedCustomers);
  const mounted = useMounted();
  const [dateSelected, setDateSelected] = useState('default');

  useEffect(() => {
    window.localStorage.setItem('recallCustomers', JSON.stringify(paginatedCustomers));
  });

  const getComments = useCallback(async () => {
    try {
      const data = await dashboardApi.getComments(dateSelected, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setCommentsCommercial(data.comments_commercial);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleDateSelected = (async (dateSelectedValue) => {
    const data = await dashboardApi.getComments(moment(dateSelectedValue).format('YYYY-MM-DD'), localStorage.getItem('accessToken'));
    setCommentsCommercial(data.comments_commercial);
  });

  return (
    <Card>
      <CardHeader title="Clients à rappeler" />
      <Formik
        initialValues={{
          dateSelected: Date.now(),
        }}
        validationSchema={Yup
          .object()
          .shape({
            dateSelected: Yup.string().max(255),
          },)}
        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
          try {
            const dateSelectedValue = moment(values.dateSelected).format('YYYY-MM-DD');
            await dashboardApi.getComments(dateSelectedValue, localStorage.getItem('accessToken'));
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, }) => (
          <form
            onSubmit={handleSubmit}
          >
            <Card>
              <Box sx={{ p: 3 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <Box>
                      <MobileDatePicker
                        error={Boolean(touched.dateSelected && errors.dateSelected)}
                        fullWidth
                        inputFormat="dd/MM/yyyy"
                        helperText={touched.dateSelected && errors.dateSelected}
                        label="Date à choisir"
                        name="dateSelected"
                        onBlur={handleBlur}
                        onAccept={() => { handleDateSelected(values.dateSelected); }}
                        onChange={(date) => setFieldValue('dateSelected', (date))}
                        renderInput={(inputProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                        value={values.dateSelected}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Box sx={{ minWidth: 700 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Nom et Commentaire
              </TableCell>
              <TableCell>
                Téléphone
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/dashboard/customers/${customer.customer_id}`}
                          variant="subtitle2"
                        >
                          {customer.firstname}
                          {' '}
                          {customer.lastname}
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {`A rappeler : ${moment(customer.recall_date).format('DD/MM/YYYY HH:mm')}`}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {customer.comment}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    color="textSecondary"
                    variant="body2"
                  >
                    {customer.phone}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default FinanceCustomersRecalls;
