/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useMounted from '../../../hooks/useMounted';
import { ticketApi } from '../../../api/ticketApi';
import TicketList from '../quality/TicketList';
import { Box } from '@mui/material';
import CustomerQualityTicketCreate from './CustomerQualityTicketCreate';

const CustomerQuality = (props) => {
  const { customerId, user , type} = props;
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [update, setUpdate] = useState(false);

  const getTickets = async (type2) => {
    setIsLoading(true); try {
      const data = await ticketApi.getTicketsByCustomer(window.localStorage.getItem('accessToken'), customerId, type2);
     setTickets(data);  
      setIsLoading(false);
    } 
    catch (err) {setIsLoading(false); console.error(err); setIsLoading(false); }
  }

  // useEffect(() => {  getTickets(type); 
  // }, [getTickets, update,]);
useEffect(() => { getTickets(type);
  }, [type, update]);
  return (
    <>
      <Box sx={{ mt: -1, mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <CustomerQualityTicketCreate customer={customerId} user={user} update={update} setUpdate={setUpdate} getTickets={getTickets} type={type} />
      </Box>
      <TicketList customerPage tickets={tickets} isLoading={isLoading} update={update} setUpdate={setUpdate}  getTickets={getTickets} type={type} />
    </>
  );
};

CustomerQuality.propTypes = {
  customerId: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomerQuality;
