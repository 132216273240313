/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MobileDateTimePicker  } from '@material-ui/lab';
import { ticketApi } from '../../../api/ticketApi';
import toast from 'react-hot-toast';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { createCustomTheme } from '../../../theme';
import useSettings from '../../../hooks/useSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { THEMES } from '../../../constants';
import { TextField, Switch, Avatar, Divider, Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid, Checkbox, Typography, Autocomplete, Box, Tabs, Tab, Card, Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import CustomerQualityComment from './QualityTicketComments';

const tabs = [
  { label: 'Commentaires', value: 'comments' },
  { label: 'Historique', value: 'Log' },
];

const severityList = [
    { code: '0', value: 'Mineur'},
    { code: '1', value: 'Modéré'},
    { code: '2', value: 'Grave' },
    { code: '3', value: 'Critique'},
  ];

const TicketDialogReferent = (props) => {
  const { show, type, handleClose, infoTicket, commentList, user, ticketComment, handleTicketCommentChange, handleKeyUp, listServices, listCategories, listMembers, setTicketSolution, handleShow, ticketLog, ticketFiles,
        ///for modifying
          setNewTicketSolution, setNewTicketState, setNewTicketDRA, newTicketSolution, newTicketDRA, newTicketAcceptTracking,
        //to compare
          oldTicketSolution, majorIncident,
        ///
          updateTicketInfo, validateTicket, update, setUpdate, getTickets
      } = props;
  const [currentTab, setCurrentTab] = useState('comments');
  const handleTabsChange = (event, value) => { setCurrentTab(value);};
  const { settings } = useSettings();
  const theme = createCustomTheme({...settings});


  const serviceprops = {options: listServices, getOptionLabel: (option) => option.service_name};
  const catprops = { options: listCategories, getOptionLabel: (option) => option.category_title };
  const memberprops = { options: listMembers,getOptionLabel: (option) => option.name};
  const severityprops = { options: severityList, getOptionLabel: (option) => option.value };


  const [showPJ, setShowPJ] = useState(false);
  const [PJFile, setPJFile] = useState(null);
  const handleclosePJ = () => {setShowPJ(false); setPJFile(null);};

  
return (
  <ThemeProvider theme={theme}>
      {/* Lecteur pièces jointes */}
    <Dialog onClose={handleclosePJ} open={showPJ} maxWidth="xl">
      <DialogTitle style={{display: "flex"}}>
          <row style={{display: 'flex'}}>
            <p style={{paddingRight: '60px', margin: '0px'}}>{PJFile?.file_name}</p>
            <div style={{marginTop: '5px', position: 'absolute', right: '30px', marginLeft: '10%'}}>
              <a download href={PJFile?.link}>
                <DownloadIcon color={theme.palette.primary.main} htmlColor={theme.palette.primary.main} />
              </a>
            </div>
          </row>
      </DialogTitle>
      <DialogContent>
        {/* le type de lecteur par extention de PJ */}
                {PJFile?.extension === 'jpg' || PJFile?.extension ===  'gif' || PJFile?.extension === 'jfif' || PJFile?.extension === 'jpeg' || PJFile?.extension === 'png' ? (<img src={PJFile?.link} height="700px" alt="test" style={{borderRadius: '6px'}} />) : null}
                {PJFile?.extension === 'mp4' || PJFile?.extension === 'webm' || PJFile?.extension === 'mkv' || PJFile?.extension === 'flv' || PJFile?.extension === 'vob' || PJFile?.extension === 'ogg' || PJFile?.extension === 'ogv' || PJFile?.extension === 'avi' || PJFile?.extension === 'gifv' || PJFile?.extension === 'mpeg' ? (<video controls width="600" height="600"><source src={PJFile?.link} type="video/mp4" /><source src={PJFile?.link} type="video/webm" /><source src={PJFile?.link} type="video/mkv" /><source src={PJFile?.link} type="video/flv" /><source src={PJFile?.link} type="video/vob" /><source src={PJFile?.link} type="video/ogg" /><source src={PJFile?.link} type="video/ogv" /><source src={PJFile?.link} type="video/gif" /><source src={PJFile?.link} type="video/gifv" /><source src={PJFile?.link} type="video/avi" /><source src={PJFile?.link} type="video/mpeg" />Error</video>) : null}
                {PJFile?.extension === 'pdf' ? (<object style={{width: '1000px'}} data={PJFile?.link} type="application/pdf" width="100%" height="500px">
                  <p>Unable to display PDF file. <a href={PJFile?.link}>Download</a> instead.</p>
                </object>) : null}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleclosePJ}> Fermer </Button>
      </DialogActions>
    </Dialog>
    {/* Affichage des tickets */}
    <Dialog onClose={handleShow} open={show} fullWidth="lg" maxWidth="lg">
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <row style={{ display: 'flex', padding: '0px' }}>   
              {/* badge d'état du ticket (Suspendu, ouvert, en cours, résolu) */}
              <div style={{ float: 'right', marginRight: '3%' }}>
                {infoTicket.state === 'Ouvert' ? (
                  <p style={{ fontSize: '15px', margin: '0px', color: 'white', backgroundColor: 'grey', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px' }}>{infoTicket.state}</p>
                ) : infoTicket.state === 'Suspendu' ? (
                  <p style={{ fontSize: '15px', margin: '0px', backgroundColor: '#f44336', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px' }}>{infoTicket.state}</p>
                ) : infoTicket.state === 'Résolu' ? (
                  <p style={{ fontSize: '15px', margin: '0px', backgroundColor: 'green', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px' }}>{infoTicket.state}</p>
                ) : infoTicket.state === 'En cours' ? (
                  <p style={{fontSize: '15px',  margin: '0px', backgroundColor: 'orange', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px' }}>{infoTicket.state}</p>
                ) : null}
              </div>   
              {/* référence du ticket */}
              <p style={{ fontSize: '15px', margin: '0px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Réf. :&#160;</p>
              <p style={{ fontSize: '15px', margin: '0px', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.ref}</p>
              {/* créateur du ticket */}
              {infoTicket.creator_id !== null ? (
                <>{type === 'ticket' ? <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur du ticket :&#160;</p>
                : <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur de la réclamation :&#160;</p>  } 
                <p style={{ fontSize: '15px', margin: '0px', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.creator_name}</p>
                </>
                ) : null}
              {/* Date de création */}
              <p style={{ fontSize: '15px', margin: '0px', marginLeft: '3%', color: theme.palette.primary.main, fontWeight: 'bold' }}>Date de création :&#160;</p> 
              <p style={{ fontSize: '15px', margin: '0%', marginLeft: '0.5%', paddingTop: '0px', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{format(new Date(infoTicket.date_creation), "dd/MM/yyyy 'à' HH:mm")}</p>
            </row>
          </Grid>
          <Grid item xs={3}>
            {infoTicket.learner !== null ? (
            <row style={{display: 'flex', }}>
              <p style={{ fontSize: '15px', margin: '0px', marginLeft: '1.5%', color: theme.palette.primary.main, fontWeight: 'bold', whiteSpace: 'nowrap' }}>Apprenant :&#160;</p> 
              <p style={{ fontSize: '15px', margin: '0px', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.learner}</p>
            </row>
            ) : null }
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              {/* service du ticket */}
            <row style={{ display: 'flex', margin: '0%', marginBottom: '0px', padding: '0%' }}>
                {type === 'ticket' &&  <row style={{ display: 'flex', margin: '0%' }}> 
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Service :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '5%', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{serviceprops.options[infoTicket.service - 1].service_name}</h4>
                </row>}
                {/* catégorie du ticket */}
                <row style={{ display: 'flex', margin: '0%', marginLeft: type === 'ticket' ? '3%' : '0%' }}> 
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Catégorie :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '3%', whiteSpace: 'nowrap', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{catprops.options[infoTicket.category_index - 1].category_title}</h4>
                </row>
                {/* gravité */}
                <row style={{ display: 'flex', margin: '0%', marginLeft: '3%' }}>
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', marginTop: '0px', verticalAlign: 'middle', color: theme.palette.primary.main}}>Gravité :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '3%', whiteSpace: 'nowrap', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{severityprops.options[parseInt(infoTicket.severity, 10)].value}</h4>
                </row>
              </row>
              {/* référent */}
              <row style={{ display: 'flex', margin: '0%', marginTop: '0px', padding: '0%'}}>
                <h4 style={{whiteSpace: 'nowrap', marginLeft: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Référent : </h4>
                <h4 style={{paddingTop: '0px', marginLeft: '0.5%', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{infoTicket.member_index !== null ? (memberprops.options[infoTicket.member_index - 1].name) : "Aucun"}</h4>
              </row>
              {/* description */}
              <row style={{ display: 'flex', margin: '0%', marginTop: '1%', padding: '0%', width: '95%', marginBottom: '10px'}}>
                <h4 style={{ whiteSpace: 'nowrap', marginTop: '0px', verticalAlign: 'middle', color: theme.palette.primary.main}}> Description :</h4>  
                <Typography variant="h4" style={{marginTop: '1px', marginBottom: '0px', color: theme.palette.dialogText.main, marginLeft: '15px', fontSize: '17px', maxHeight: '200px', overflowY: 'auto', padding: '0px', verticalAlign: 'middle'}}>{infoTicket.description}</Typography>
              </row>
              {/* pièces jointes */}
              <row style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0px', paddingTop: '15px' }}>
                  <h4 style={{paddingTop: '6px', color: theme.palette.primary.main}}> Pièces jointes :</h4>
                  {/* liste des PJ */}
                  <div style={{maxHeight: '90px', overflowY: 'auto', width: '80%', flexWrap: 'wrap', display: 'flex'}}>
                    {ticketFiles.map((file) => (
                      <>
                        {file.extension === 'jpg' || file.extension === 'jfif' || file.extension === 'jpeg' || file.extension === 'png' || file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv' || file.extension ===  'gif' || file.extension === 'avi' || file.extension === 'gifv' || file.extension === 'mpeg' || file.extension === 'pdf' ? 
                        (<Card sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }} style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
                        title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `} onClick={() => { setShowPJ(true); setPJFile(file);}}>
                          {/* <UploadFileIcon fontSize="medium" /> */}
                          {file.extension === 'pdf' ? (<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png" height="40px" width="40px" alt="test" style={{borderRadius: '6px'}} />)
                          : file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv' || file.extension === 'avi' || file.extension === 'gifv' || file.extension === 'mpeg' ? (
                            <PlayArrowIcon />
                          ) : (file.extension === 'jpg' || file.extension ===  'gif' || file.extension === 'jfif' || file.extension === 'jpeg' || file.extension === 'png' ? (<img src={file.link} height="40px" width="40px" alt="test" style={{borderRadius: '6px'}} />) : null)}
                          &nbsp;
                          {file.file_name}
                            </Card>
                        ) : (
                        <a href={file.link} rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                        <Card sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }} style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
                        title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `}>
                          <InsertDriveFileIcon fontSize="large" />
                          &nbsp;
                          {file.file_name}
                            </Card></a>) }
                      </> 
                    ))}
                  </div> 
                </row>
                {/* suivi du ticket */}
                <row style={{ display: 'flex', marginTop: '1%', paddingTop: '0px', marginBottom: '1%'  }}>
                  {type === 'ticket' ?  <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi du ticket :&#160;</p>
                  : <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi de la réclamation :&#160;</p>}
                  <Switch checked={newTicketAcceptTracking}
                  />
                </row>
                <div>
                  {/* onglets commentaires et log */}
                  <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                    {tabs.map((tab) => (<Tab key={tab.value} label={tab.label} value={tab.value} /> )) }
                  </Tabs>
                  {currentTab === 'comments' && (
                    <div style={{ width: '95%', marginTop: '10px', minHeight: '150px', maxHeight: '150px' }}>
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Avatar sx={{ mr: 2 }} src={user.avatar} />  
                        <TextField style={{ width: '90%' }} onKeyUp={handleKeyUp} fullWidth onChange={handleTicketCommentChange} placeholder="Ajouter un commentaire..." size="small" value={ticketComment} variant="outlined" multiline />
                      </Box>
                      <div style={{ width: '100%', maxHeight: '117px', overflowY: 'auto', marginTop: '10px' }}>
                        {/* CustomerQualityComment : composant qui affiche lescommentaires  */}
                        {commentList.slice().reverse().map((comment) => ( <CustomerQualityComment comment={comment} />))} 
                      </div>
                    </div>
                  )}
                  {/* les logs du ticket */}
                  {currentTab === 'Log' && (
                    <div style={{maxHeight: '160px', width: '100%', overflowY: 'auto', minHeight: '160px'}}>
                      {ticketLog.map((event) => (
                        <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
                          <div style={{marginRight: '2%'}}>
                            <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main}}> {format(new Date(event.time), "dd/MM/yyyy 'à' HH:mm:ss")}</h5>
                          </div>
                          <div style={{marginRight: '1%'}}> 
                            <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main }}>{event.user_name}</h5>
                          </div>
                          <div style={{ width: '70%', marginRight: '1%', marginTop: '2px'}}>
                            <p style={{ marginRight: '10px', padding: '0px', margin: '0px', color: theme.palette.dialogText.main }}>{event.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
            </Grid>
            <Grid item xs={3} minWidth="20%" style={{ borderLeft: '2px solid' }}>
                {/* affichage des infos incidents majeurs s'ils existent */}
                {user.id === infoTicket.referent_id || user.id === infoTicket.creator_id || infoTicket.referent_id === null ? (
                  <div> 
                    <TextField multiline label="Actions correctives" placeholder="" rows={3} value={newTicketSolution} style={{ width: '100%', marginTop: '0px', marginBottom: '10px' }} onChange={(e) => {setTicketSolution(e.target.value); setNewTicketSolution(e.target.value); if (newTicketSolution !== null && newTicketSolution !== '') {setNewTicketState('Résolu'); } }} />
                    {infoTicket.solution_major_incident ? (
                      <Accordion style={{width: '100%', marginBottom: '20px'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                          <Typography color="primary" style={{fontWeight: 'bold'}}>Incident majeur : MI {majorIncident.incident_id}</Typography>
                        </AccordionSummary>
                        <div style={{maxHeight: '150px', overflowY: 'auto', padding: '0px', width: '100%'}}>
                          <AccordionDetails style={{margin: '0px', padding: '0px'}}>
                            <row style={{display: 'flex'}}>
                              <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de début :  </Typography>
                              <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{format(new Date(majorIncident.start_date), "dd/MM/yyyy à HH:mm")}</p>
                            </row>
                            
                            <row style={{display: 'flex', marginTop: '10px'}}>
                            <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de fin :  </Typography>
                            <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.end_date ? (format(new Date(majorIncident.end_date), "dd/MM/yyyy à HH:mm")) : "-" }</p>
                            </row>
                  
                            <row style={{display: 'flex', marginTop: '10px'}}>
                            <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Créateur :  </Typography>
                            <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.creator_name}</p>
                            </row>
                  
                            <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                            <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Description d&apos;incident :  </Typography>
                            <p style={{padding: '0px', margin: '0px'}}>{majorIncident.description}</p>
                            </row>
                  
                            <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                            <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Actions correctives :  </Typography>
                            <p style={{ padding: '0px', margin: '0px'}}>{majorIncident.solution}</p>
                            </row>
                          </AccordionDetails>
                        </div>
                      </Accordion>
                    ) : null }
                  </div>
                  ) : (
                        <div style={{marginBottom: '0px'}}>
                          {/* l'affichage des info I M et les solutions */}
                          {infoTicket.solution_major_incident && newTicketSolution ? (
                            <div>
                              <row style={{ display: 'flex', marginBottom: '20px', flexWrap: 'wrap', marginTop: '0px' }}>
                                <Typography color="primary" style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', marginRight: '5px', color: 'primary', fontWeight: 'bold'}}>Actions correctives spécifique du ticket :</Typography>
                                <div style={{margin: '0px', padding: '0px', maxHeight: '80px', overflowY: 'auto'}}>
                                  <p style={{whiteSpace: 'pre-line', color: 'primary', padding: '0px', margin: '0px', fontWeight: 'bold'}}>{infoTicket.solution === null || infoTicket.solution === '' ? 'Pas encore résolu' :  newTicketSolution}</p>
                                </div>
                              </row>
                              <Accordion style={{width: '100%', marginBottom: '20px'}}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography color="primary" style={{fontWeight: 'bold'}}>Incident majeur : MI {majorIncident.incident_id}</Typography>
                                  </AccordionSummary>
                                  <div style={{maxHeight: '150px', overflowY: 'auto', padding: '10px', width: '100%'}}>
                                    <AccordionDetails style={{margin: '0px', padding: '0px'}}>
                                      <row style={{display: 'flex'}}>
                                        <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de début :  </Typography>
                                        <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{format(new Date(majorIncident.start_date), "dd/MM/yyyy à HH:mm")}</p>
                                      </row>
                                      <row style={{display: 'flex', marginTop: '10px'}}>
                                        <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de fin :  </Typography>
                                        <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.end_date ? (format(new Date(majorIncident.end_date), "dd/MM/yyyy à HH:mm")) : "-" }</p>
                                      </row>
                                      <row style={{display: 'flex', marginTop: '10px'}}>
                                        <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Créateur :  </Typography>
                                        <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.creator_name}</p>
                                      </row>
                                      <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                                        <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Description d&apos;incident :  </Typography>
                                        <p style={{padding: '0px', margin: '0px'}}>{majorIncident.description}</p>
                                      </row>
                                      <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                                        <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Actions correctives :  </Typography>
                                        <p style={{ padding: '0px', margin: '0px'}}>{majorIncident.solution}</p>
                                      </row>
                                    </AccordionDetails>
                                  </div>
                              </Accordion>
                            </div>
                            ) : null }
                            {/* champs d'ajout des solution si le ticket n'est pas lié à un incident majeur */}
                            {(infoTicket.solution_major_incident === null && newTicketSolution) || (infoTicket.solution_major_incident === null && newTicketSolution === '') ? (
                              <row style={{ display: 'flex', marginBottom: '20px', flexWrap: 'wrap', marginTop: '0px' }}>
                                <Typography color="primary" style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', marginRight: '5px', color: 'primary', fontWeight: 'bold'}}>Actions correctives du ticket :</Typography>
                                <div style={{margin: '0px', padding: '0px', maxHeight: '80px', overflowY: 'auto'}}>
                                  <p style={{whiteSpace: 'pre-line', color: 'primary', padding: '0px', margin: '0px', fontWeight: 'bold'}}>{infoTicket.solution === null || infoTicket.solution === '' ? 'Pas encore résolu' :  newTicketSolution}</p>
                                </div>
                              </row>
                            ) : null}
                            {/* champs d'ajout des solution et l'affichage des infi incidents majeurs si le ticket est  lié à un incident majeur */}
                            {(infoTicket.solution_major_incident !== null && newTicketSolution === null) || (infoTicket.solution_major_incident !== null && newTicketSolution === '') ? (
                              <>
                                <row style={{ display: 'flex', marginBottom: '20px', flexWrap: 'wrap', marginTop: '0px' }}>
                                  <Typography color="primary" style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', marginRight: '5px', color: 'primary', fontWeight: 'bold'}}>Actions correctives du ticket :</Typography>
                                  <div style={{margin: '0px', padding: '0px', maxHeight: '80px', overflowY: 'auto'}}>
                                    <p style={{whiteSpace: 'pre-line', color: 'primary', padding: '0px', margin: '0px', fontWeight: 'bold'}}>{infoTicket.solution === null || infoTicket.solution === '' ? 'Pas encore résolu' :  newTicketSolution}</p>
                                  </div>
                                </row>
                                <Accordion style={{width: '100%', marginBottom: '20px'}}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography color="primary" style={{fontWeight: 'bold'}}>Incident majeur : MI {majorIncident.incident_id}</Typography>
                                  </AccordionSummary>
                                    <div style={{maxHeight: '150px', overflowY: 'auto', padding: '10px', width: '100%'}}>
                                      <AccordionDetails style={{margin: '0px', padding: '0px'}}>
                                        <row style={{display: 'flex'}}>
                                          <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de début :  </Typography>
                                          <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{format(new Date(majorIncident.start_date), "dd/MM/yyyy à HH:mm")}</p>
                                        </row>
                                        
                                        <row style={{display: 'flex', marginTop: '10px'}}>
                                          <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Date de fin :  </Typography>
                                          <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.end_date ? (format(new Date(majorIncident.end_date), "dd/MM/yyyy à HH:mm")) : "-" }</p>
                                        </row>
                              
                                        <row style={{display: 'flex', marginTop: '10px'}}>
                                          <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Créateur :  </Typography>
                                          <p style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', justifyContent: 'flex-end', marginLeft: 'auto'}}>{majorIncident.creator_name}</p>
                                        </row>
                              
                                        <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                                          <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Description d&apos;incident :  </Typography>
                                          <p style={{padding: '0px', margin: '0px'}}>{majorIncident.description}</p>
                                        </row>
                              
                                        <row style={{flexWrap: 'wrap', display: 'flex', marginTop: '10px'}}>
                                          <Typography color="primary" style={{marginRight: '10px', fontWeight: 'bold', whiteSpace: 'nowrap'}}> Actions correctives :  </Typography>
                                          <p style={{ padding: '0px', margin: '0px'}}>{majorIncident.solution}</p>
                                        </row>
                                      </AccordionDetails>
                                    </div>
                                </Accordion>
                              </>
                          ) : null}
                          {/* si le ticket n'est pas résolu et il n'y a pas de solution MI */}
                          {infoTicket.solution_major_incident === null && newTicketSolution === null ? (
                            <row style={{ display: 'flex', marginBottom: '20px', flexWrap: 'wrap', marginTop: '0px' }}>
                              <Typography color="primary" style={{padding: '0px', margin: '0px', whiteSpace: 'nowrap', marginRight: '5px', color: 'primary', fontWeight: 'bold'}}>{ type === 'ticket' ? 'Actions correctives du ticket :' : 'Actions correctives de la réclamation :'}</Typography>
                              <div style={{margin: '0px', padding: '0px', maxHeight: '80px', overflowY: 'auto'}}>
                                <p style={{whiteSpace: 'pre-line', color: 'primary', padding: '0px', margin: '0px', fontWeight: 'bold'}}>{infoTicket.solution === null || infoTicket.solution === '' ? 'Pas encore résolu' :  newTicketSolution}</p>
                              </div>
                            </row>
                          ) : null}
                        </div> 
                      )
                }          
                <Divider style={{  marginBottom: '5%', }} />
                {/* les dates des tickets */}
                <div>
                  {/* Date de prise en charge */}
                  {infoTicket.date_take_charge !== null ? (
                    <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                      <MobileDateTimePicker ampm={false} cancelText="Annuler" showTodayButton todayText="Aujourd'hui" locale={fr} sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy 'à' HH:mm"
                                            readOnly disabled label="Date de prise en charge : " name="dateprisecharge" value={infoTicket.date_take_charge}
                                            renderInput={(inputProps) => (
                                              <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                            )} />
                    </div>
                    ) : (
                    <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                      <MobileDateTimePicker ampm={false} cancelText="Annuler" todayText="Aujourd'hui" showTodayButton locale={fr} sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy 'à' HH:mm"
                                            readOnly disabled label="Date de prise en charge : " name="dateprisecharge" value={null}
                                            renderInput={(inputProps) => (
                                              <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                            )} />
                    </div>
                    ) 
                  }
                  {/* Date de résolution approximative */}
                  {infoTicket.date_res_approx !== null ? (
                    <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                      <MobileDateTimePicker ampm={false} cancelText="Annuler" todayText="Aujourd'hui" showTodayButton locale={fr} sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy 'à' HH:mm "
                                            disabled={infoTicket.state === 'Résolu'} label="Date de résolution approximative" name="dateprisecharge" value={newTicketDRA}
                                            onChange={(e) => { setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));}}
                                            renderInput={(inputProps) => (
                                              <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                            )} />
                    </div>
                    ) : ( 
                    <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}>
                      <MobileDateTimePicker ampm={false} cancelText="Annuler" todayText="Aujourd'hui" showTodayButton locale={fr} sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy à' HH:mm"
                                            readOnly disabled label="Date de résolution approximative" name="dateprisecharge" value={null}
                                            onChange={(e) => { setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));}}
                                            renderInput={(inputProps) => (
                                              <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                            )} />
                    </div>
                    )}
                  {/* Date de résolution effective */}
                  {infoTicket.date_res_eff !== null ? (
                      <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}> 
                        <MobileDateTimePicker ampm={false} cancelText="Annuler" showTodayButton todayText="Aujourd'hui" locale={fr} sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy 'à' HH:mm"
                                              readOnly disabled label="Date de résolution effective" name="dateprisecharge"  value={infoTicket.date_res_eff}
                                              renderInput={(inputProps) => (
                                                <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                              )} />
                      </div>
                      ) : (
                      <div style={{ width: '80%', marginLeft: '5%', marginBottom: '5%' }}> 
                        <MobileDateTimePicker ampm={false} cancelText="Annuler" showTodayButton todayText="Aujourd'hui" sx={{ padding: 0, margin: 0 }} inputFormat="dd/MM/yyyy 'à' HH:mm"
                                              readOnly disabled label="Date de résolution effective" name="dateprisecharge"  value={null}
                                              renderInput={(inputProps) => (
                                                <TextField style={{ padding: '0px', margin: '0px' }} fullWidth variant="outlined" {...inputProps} />
                                              )} />
                      </div>
                      )}
                        
                </div>

            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose(); handleShow();}} color="primary"> Fermer</Button>
        {newTicketSolution !== oldTicketSolution ? (
        <Button onClick={() => { handleClose(); handleShow(); updateTicketInfo(user.id, infoTicket.ticketId); getTickets(); setUpdate(!update);}} color="primary"> {/* disabled={!(newTicketService !== null && newTicketService !== '' && newTicketSeverity !== null && newTicketSeverity !== '' && newTicketSeverity !== null && newTicketSeverity !== '')} */}
          Enregistrer les modifications
        </Button>
        ) : (
        (infoTicket.state === 'Ouvert' || infoTicket.state === 'Suspendu') && (infoTicket.referent_id === user.id || infoTicket.referent_id === null) && (
          <Button onClick={() => { handleClose(); handleShow(); getTickets(); setUpdate(!update); validateTicket(infoTicket.ticketId);}} color="primary">
            {/* // disabled={!(newTicketService !== null && newTicketService !== '' && newTicketSeverity !== null && newTicketSeverity !== '' && newTicketSeverity !== null && newTicketSeverity !== '')} */}
            Valider
          </Button>
        )
        )}
      </DialogActions>
    </Dialog>
  </ThemeProvider>
);
 };
TicketDialogReferent.propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    infoTicket: PropTypes.object.isRequired,
    commentList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    ticketComment: PropTypes.string,
    handleTicketCommentChange: PropTypes.func.isRequired,
    handleKeyUp: PropTypes.func.isRequired,
    listServices: PropTypes.array.isRequired,
    listCategories: PropTypes.array.isRequired,
    listMembers: PropTypes.array.isRequired,
    setTicketSolution: PropTypes.func.isRequired,
    handleShow: PropTypes.func.isRequired,
    ticketLog: PropTypes.array.isRequired,
    ticketFiles: PropTypes.array.isRequired,
    ///for modifying
    setNewTicketSolution: PropTypes.func.isRequired,
    setNewTicketState: PropTypes.func.isRequired,
    setNewTicketDRA: PropTypes.func.isRequired,
    newTicketService: PropTypes.number.isRequired,
    newTicketSeverity: PropTypes.number.isRequired,
    newTicketSolution: PropTypes.string.isRequired,
    newTicketState: PropTypes.string.isRequired,
    newTicketDRA: PropTypes.instanceOf(Date).isRequired,
    newTicketAcceptTracking: PropTypes.bool.isRequired,
    ////////////////to compare
    oldTicketSolution: PropTypes.string.isRequired,
    updateTicketInfo: PropTypes.func.isRequired,
    validateTicket: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired,
    setUpdate: PropTypes.func.isRequired,
    majorIncident: PropTypes.object.isRequired,
    getTickets: PropTypes.func.isRequired,
  };
  
  export default TicketDialogReferent;
