import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Box, Button, Container,Divider, Grid, Tab,Tabs,Typography} from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import useAuth from '../../hooks/useAuth';
import TicketList from '../../components/dashboard/quality/TicketList';
import { ticketApi } from '../../api/ticketApi';
import FormMajorIncident from '../../components/dashboard/quality/FormMajorIncident';
import MajorIncidentList from '../../components/dashboard/quality/MajorIncidentList';
import QualityTicketCreate from '../../components/dashboard/quality/QualityTicketCreate';

const tabs = [
  { label: 'Direction', value: 'Direction' },
  { label: 'Informatique', value: 'Informatique' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Administratif', value: 'Administratif' },
  { label: 'Mes tickets', value: 'ticketsPerso' }
];
const tabs_complaint = [
  { label: 'Client', value: 'Réclamation Client' },
  { label: 'Prestataire', value: 'Réclamation Prestataire' },
  { label: 'Formateur', value: 'Réclamation Formateur' },
  { label: 'Collaborateur', value: 'Réclamation Collaborateur' },
  { label: 'Mes réclamations', value: 'ticketsPerso' }
];
const QualityPage = (props) => {
  const {type} = props;
  const [tickets, setTickets] = useState([]);
  const { user } = useAuth();
  const { settings } = useSettings();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(null);
  const [isListTickets, setIsListTickets] = useState(true);
  const [majorIncidents, setMajorIncidents] = useState([]);
  const [update, setUpdate] = useState(false);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const listToggle = () => {
    setIsListTickets(!isListTickets);
  };

  const getTickets = async () => {
    try {
      if (currentTab === 'ticketsPerso') {
        const data = await ticketApi.getTicketsByCreator(window.localStorage.getItem('accessToken'), user.id, type);
        setTickets(data);
        setIsLoading(false);
      } else {
        const data = await ticketApi.getTicketsByServiceName(window.localStorage.getItem('accessToken'), currentTab, type);
        setTickets(data);
        setIsLoading(false);
      }
    } catch (err) { setIsLoading(false);
      console.error(err);
    }
  };

  const getMajorIncidentsService = async () => {
    try {
      const majorIncidentsData = await ticketApi.getMajorIncidentsService(localStorage.getItem('accessToken'), currentTab);
      setMajorIncidents(majorIncidentsData);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {  console.log(currentTab); console.log(user.service); 
    if (isListTickets) { setTickets([]); setIsLoading(true);  getTickets();} 
    else if (currentTab === 'ticketsPerso') { setTickets([]); setIsLoading(true); getTickets();}
    else { setMajorIncidents([]); setIsLoading(true); getMajorIncidentsService();}
  }, [currentTab, isListTickets, update]);

  useEffect(() => {setTickets([]); setIsLoading(true); getTickets(); setCurrentTab(type === 'ticket' ? user.service : 'Réclamation Client')}, [type])

  return (
    <>
      <Helmet>
       {type === 'ticket' ? <title>Liste des tickets | Apteed</title> : <title>Liste des réclamations | Apteed</title>}
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                { isListTickets ?  type === "ticket" ? 'Liste des tickets' : 'Liste des réclamations' : type === "ticket" && 'Liste des incidents majeurs' }
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
              {/*  si l'utilisateur est un responsable  */}
              {(user.access_level === 0 && user.id_service === 0) ? 
                  // si on est sur les tickets
                  type === 'ticket' ? tabs.map((tab) => ( <Tab key={tab.value} label={tab.label} value={tab.value} /> )) :
                  // si on est sur les réclamations
                  tabs_complaint.map((tab) => ( <Tab key={tab.value}  label={tab.label} value={tab.value} /> ))
                  // si on est pas un responsable
                  // si on est sur les tickets
                  : type === 'ticket' ? tabs.filter((tab) => tab.value === user.service || tab.value === 'ticketsPerso').map((filteredService) => (
                    <Tab key={filteredService.value} label={filteredService.label} value={filteredService.value} />
                  // si on est sur les réclamations
                  )) :  tabs_complaint.map((filteredService) => (
                    <Tab key={filteredService.value}  label={filteredService.label} value={filteredService.value} />
                  ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ my: 2, display: 'flex', justifyContent: currentTab !== 'ticketsPerso' ? 'space-between' : 'flex-end' }}>
            {currentTab !== 'ticketsPerso' &&  type === 'ticket'
              ? ( 
                // ce bouton s'affiche seulement sur la page des tickets et non pas sur les réclamations sur les ongles sauf "MES TICKETS"
              <Button color="primary" variant="outlined" onClick={listToggle}> { isListTickets ? 'Voir les incidents majeurs' :  'Voir les tickets du service' }</Button> // si on click ça va changer le titre 
              ) : <Box></Box>}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {(user.service_manager || (user.access_level === 0 && user.id_service === 0)) && currentTab !== 'ticketsPerso' &&  type === 'ticket'
              ? ( // le composant des incidents majeurs ne s'affiche que sur la page des tickets si type === 'ticket'
              <FormMajorIncident user={user} serviceTab={currentTab} update={update} setUpdate={setUpdate} isListTickets={isListTickets} listToggle={listToggle} setCurrentTab={user.id_service === 0 && setCurrentTab} />
              ) : null}
              {/* la création des ticket ou des réclamations (ça dépend du string "type") */}
              <QualityTicketCreate type={type}  user={user} update={update} setUpdate={setUpdate} isListTickets={isListTickets} listToggle={listToggle} currentTab={currentTab} setCurrentTab={setCurrentTab} getTickets={getTickets} />
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            {/* isListTicket montre si on affiche la liste des tickets ou la liste des incidents majeurs */}
            {isListTickets || currentTab === 'ticketsPerso' 
              ? (
                // la liste des tickets ou des réclamations (dépend du string type)
                <TicketList  type={type} tickets={tickets} isLoading={isLoading} update={update} setUpdate={setUpdate}  getTickets={getTickets} />
              ) : (
                //list des incidents majeurs
              <MajorIncidentList  isLoading={isLoading}  majorIncidents={majorIncidents} setMajorIncidents={setMajorIncidents} currentTab={currentTab} updateList={update} setUpdateList={setUpdate} />
              ) }
          </Box>
        </Container>
      </Box>

    </>
  );
};

export default QualityPage;
QualityPage.propTypes = {
  type: PropTypes.string.isRequired,
};
