class FormationApi {
  async getFormations(accessToken) {
    const urlFormations = `${process.env.REACT_APP_API_URL}/api/formations`;

    const resp = await fetch(urlFormations, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getAllInfosFiltersFormations(accessToken) {
    const urlFormations = `${process.env.REACT_APP_API_URL}/api/getAllInfosFiltersFormations`;

    const resp = await fetch(urlFormations, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  // async getAllFonctionsFormations(accessToken) {
  //   const urlFormations = `${process.env.REACT_APP_API_URL}/api/getAllFonctionsFormations`;

  //   const resp = await fetch(urlFormations, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   });

  //   const json = await resp.json();

  //   return Promise.resolve(json.data);
  // }

  // async getAllFormatFormations(accessToken) {
  //   const urlFormations = `${process.env.REACT_APP_API_URL}/api/getAllFormatFormations`;

  //   const resp = await fetch(urlFormations, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   });

  //   const json = await resp.json();

  //   return Promise.resolve(json.data);
  // }

  // async getAllActionTypesFormations(accessToken) {
  //   const urlFormations = `${process.env.REACT_APP_API_URL}/api/getAllActionTypesFormations`;

  //   const resp = await fetch(urlFormations, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   });

  //   const json = await resp.json();

  //   return Promise.resolve(json.data);
  // }

  async getOrientations(year, accessToken) {
    const urlOrientations = `${process.env.REACT_APP_API_URL}/api/orientations`;
    const resp = await fetch(urlOrientations, {
      method: 'POST',
      body: JSON.stringify(year),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getFormation(idFormation, accessToken) {
    const urlFormation = `${process.env.REACT_APP_API_URL}/api/formations/${idFormation}`;

    const resp = await fetch(urlFormation, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async updateFormation(FormationId, values, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/formations/update-formation`;
    values.id = FormationId;
    const resp = await fetch(URL_UPDATE, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async deleteFormation(formationId, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/formations/delete-formation`;
    const resp = await fetch(URL_UPDATE, {
      method: 'POST',
      body: JSON.stringify(formationId),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async editFormation(field, value, formationId, accessToken) {
    const URL_EDIT = `${process.env.REACT_APP_API_URL}/api/formations/edit_formation`;
    const resp = await fetch(URL_EDIT, {
      method: 'POST',
      body: JSON.stringify({ field, value, formationId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getPrices(formationId, accessToken) {
    const URL_EDIT = `${process.env.REACT_APP_API_URL}/api/formations/prices`;
    const resp = await fetch(URL_EDIT, {
      method: 'POST',
      body: JSON.stringify(formationId),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async setPublic(pub, formationId, accessToken) {
    const URL_EDIT = `${process.env.REACT_APP_API_URL}/api/formations/set-public`;
    const resp = await fetch(URL_EDIT, {
      method: 'POST',
      body: JSON.stringify({ pub, formationId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createFormation(values, accessToken) {
    const URL_CREATE = `${process.env.REACT_APP_API_URL}/api/formations/create-formation`;
    const resp = await fetch(URL_CREATE, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async deleteSession(id, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/formations/delete-session`;
    const resp = await fetch(URL_DELETE, {
      method: 'POST',
      body: JSON.stringify(id),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getUnits(formationId, accessToken) {
    const URL_UNITS = `${process.env.REACT_APP_API_URL}/api/formations/get-units`;
    const resp = await fetch(URL_UNITS, {
      method: 'POST',
      body: JSON.stringify(formationId),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }

  async modifyUnits(modifyUnits, accessToken) {
    const URL_MODIFY_UNITS = `${process.env.REACT_APP_API_URL}/api/formations/modify-units`;
    const resp = await fetch(URL_MODIFY_UNITS, {
      method: 'POST',
      body: JSON.stringify(modifyUnits),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }

  async deleteUnit(unitId, accessToken) {
    const URL_DELETE_UNIT = `${process.env.REACT_APP_API_URL}/api/formations/delete-unit`;
    const resp = await fetch(URL_DELETE_UNIT, {
      method: 'POST',
      body: JSON.stringify(unitId),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }

  async addUnit(unitAdd, formationId, orderUnit, accessToken) {
    const URL_ADD_UNIT = `${process.env.REACT_APP_API_URL}/api/formations/add-unit`;
    const resp = await fetch(URL_ADD_UNIT, {
      method: 'POST',
      body: JSON.stringify({ unitAdd, formationId, orderUnit }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }

  async getCampaignLogs(formationId, sort = null, user, category, type, limit = 25, page = 0, accessToken) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/formation/logs`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.formationId = formationId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCampaignLogsInfos(formationId, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/formation/logs/infos/${formationId}`;

    const resp = await fetch(urlCustomer, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogs(accessToken, formationId, sort = null, user, category, type, limit = 25, page = 0) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/formation/logs/filtered`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.formationId = formationId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getFormationPerformance(formationId, accessToken, userId) {
    const urlLogs = `${process.env.REACT_APP_API_DJANGO_URL}/api/courses_performance/`;
    const values = {};
    values.course_id = formationId;
    values.user_id = userId;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    // console.log(json);
    return Promise.resolve(json);
  }

  async getSessionPerformance(sessionId, accessToken, userId) {
    const urlLogs = `${process.env.REACT_APP_API_DJANGO_URL}/api/session_performance/`;
    const values = {};
    values.session_id = sessionId;
    values.user_id = userId;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    // console.log(json);
    return Promise.resolve(json);
  }

 async getCoursesSatisfaction(accessToken, formationId, userId) {
    const urlCourseSatisfactions = `${process.env.REACT_APP_API_DJANGO_URL}/api/courses_satisfaction/`;

    const resp = await fetch(urlCourseSatisfactions, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId,
        course_id : formationId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
   } });
    const json = await resp.json();
    // console.log(json);
    return Promise.resolve(json);
  }

  async getSessionSatisfaction(accessToken, sessionId, userId) {
    const urlCourseSatisfactions = `${process.env.REACT_APP_API_DJANGO_URL}/api/session_satisfaction/`;

    const resp = await fetch(urlCourseSatisfactions, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId,
        session_id : sessionId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
   } });
    const json = await resp.json();
    // console.log(json);
    return Promise.resolve(json);
  }

  async getFormationsForSatisfactions(accessToken, userId) {
    const urlFormations = `${process.env.REACT_APP_API_DJANGO_URL}/api/satisfaction_table/`;

    const resp = await fetch(urlFormations, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json);
  }
  
  async getFormationsForPerformances(accessToken, userId) {
    const urlFormations = `${process.env.REACT_APP_API_DJANGO_URL}/api/performance_table/`;

    const resp = await fetch(urlFormations, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json);
  }

  async getPerformanceFormationGraphsDataBySessions(formationId, accessToken, userId) {
    const urlFormations = `${process.env.REACT_APP_API_DJANGO_URL}/api/performance_graph/`;

    const resp = await fetch(urlFormations, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId,
        course_id : formationId,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json);
  }

  async getSatisfactionFormationGraphsDataBySessions(formationId, accessToken, userId) {
    const urlFormations = `${process.env.REACT_APP_API_DJANGO_URL}/api/satisfaction_graph/`;

    const resp = await fetch(urlFormations, {
      method: 'POST',
      body : JSON.stringify({
        user_id : userId,
        course_id : formationId,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json);
  }
}

export const formationApi = new FormationApi();
