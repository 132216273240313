import PropTypes from 'prop-types';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import * as moment from 'moment';

const COL1_WIDTH = 30;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;
const COL2_WIDTH = 22;
const COL3_WIDTH = 15;
const COL4_WIDTH = 30;
const COL5_WIDTH = 15;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 30,
    color: '#7EB627',
    border: '15px solid rgba(0, 0, 0, 0.05)'
  },
  h1: {
    fontSize: 30,
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    color: '#1B2445'
  },
  h4: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1
  },
  hm: {
    fontSize: 13,
    textAlign: 'left',
  },
  hm1: {
    fontSize: 13,
    marginLeft: -80
  },
  hm2: {
    fontSize: 13,
    marginLeft: 10
  },
  hm3: {
    marginLeft: 80
  },
  hm4: {
    marginLeft: -80
  },
  h6: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  h8: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.3,
    color: '#1B2445'
  },
  subtitle2: {
    fontSize: 10,
    fontWeight: 100,

    color: '#1B2445'
  },
  body3: {
    fontSize: 8,
    lineHeight: 1.30,
    lineWeight: 100,
    color: '#1B2445'
  },
  body2: {
    fontSize: 10,
    lineHeight: 1.70,
    lineWeight: 100,
    color: '#1B2445'
  },
  body1: {
    fontSize: 10,
    lineHeight: 1.60,
    lineWeight: 100,
    marginTop: 5
  },
  gutterBottom: {
    marginBottom: 4
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  brand: {
    height: 130,
    width: 170,
    marginTop: -40
  },
  brand2: {
    height: 100,
    width: 100,
    padding: 5,
    marginTop: -10
  },
  company: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10
  },
  references: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
  },
  references1: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -40
  },
  references2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -90
  },
  billing: {
    marginTop: 15
  },
  items: {
    marginTop: 15
  },
  notes: {
    marginTop: 15
  },
  table: {
    display: 'flex',
    width: 'auto'
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#1B2445',
    borderStyle: 'solid',
    flexDirection: 'row',
    width: `${COL2_WIDTH}%`
  },
  tableCell1: {
    width: `${COL1_WIDTH}%`
  },
  tableCell4: {
    width: `${COL4_WIDTH}%`
  },
  tableCell5: {
    width: `${COL5_WIDTH}%`
  },
  tableCell2: {
    width: `${COL3_WIDTH}%`,
    marginLeft: 100
  },
  tableCellN: {
    padding: 6,
    width: `${COLN_WIDTH}%`
  },
  alignRight: {
    textAlign: 'right'
  }
});

const AttestationPDF = (props) => {
  const { formation, customer, orientations, ...other } = props;
  const birthdayToFr = typeof customer.birthday !== 'undefined' && customer.birthday !== null ? moment(customer.birthday).format('DD/MM/YYYY') : 'Non enregistrée';
  const datefin = formation.end_date !== null ? moment(formation.end_date).format('DD/MM/YYYY') : 'Non enregistrée';
  const datestart = formation.start_date !== null ? moment(formation.start_date).format('DD/MM/YYYY') : 'Non enregistrée';
  const orientationsArray = formation.orientations_dpc_ids !== null ? formation.orientations_dpc_ids.split('-').map((ortDpcIds) => parseInt(ortDpcIds, 10)) : null;
  const orientationsDpc = orientationsArray !== null ? orientations.filter((ort) => orientationsArray.includes(ort.id)) : null;
  return (
    <Document>
      <Page
        {...other}
        size="A4"
        orientation="landscape"
        style={[styles.page]}
      >
        <View style={styles.header}>
          <View>
            <Text
              color="textPrimary"
              style={styles.h1}
            >
              ATTESTATION DPC
            </Text>
          </View>
        </View>
        <View
          color="textPrimary"
          style={styles.company}
        >
          <View style={styles.tableRow}>
            <Text style={styles.h8}>
              Informations participant
            </Text>
          </View>
          <View />
          <View />
        </View>
        <View style={styles.references}>
          <View>
            <Text
              color="textPrimary"
              style={[styles.h6, styles.gutterBottom]}
            >
              Ce certificat est décerné à
            </Text>
            <Text style={styles.body2}>
              {customer.firstname}
              {'  '}
              {customer.lastname}
            </Text>
          </View>
          <View>
            <Text
              color="textPrimary"
              style={[styles.hm, styles.gutterBottom]}
            >
              Numéro RPPS
            </Text>
            <Text style={styles.body2}>
              {customer.adeli_rpps}
            </Text>
          </View>
          <Image
            source="/static/diplome.png"
            style={styles.brand}
          />
        </View>
        <View style={styles.references1}>
          <View>
            <Text
              color="textPrimary"
              style={[styles.h6, styles.gutterBottom]}
            >
              Né(e) le
            </Text>
            <Text
              style={styles.body2}
            >
              {birthdayToFr}
            </Text>
          </View>
          <View style={styles.hm1}>
            <Text
              color="textPrimary"
              style={[styles.hm, styles.gutterBottom]}
            >
              Profession
            </Text>
            <Text style={styles.body2}>
              {customer.profession}
            </Text>
          </View>
          <View />
        </View>
        <View style={styles.references}>
          <View>
            <Text
              color="textPrimary"
              style={[styles.h6, styles.gutterBottom]}
            >
              Domicilié(e) au
            </Text>
            <Text style={[styles.body3, styles.tableCell4]}>
              {customer.adress}
            </Text>
            <Text style={styles.body3}>
              {`${customer.zip} ${customer.city}`}
            </Text>
          </View>
          <View style={styles.hm2}>
            <Text
              color="textPrimary"
              style={[styles.hm, styles.gutterBottom]}
            >
              Mode d&rsquo;exercice dominant &gt; 50%
            </Text>
            <Text style={styles.body2}>
              Libéral
            </Text>
          </View>
        </View>
        <View style={styles.references}>
          <View>
            <View style={styles.tableRow}>
              <Text
                color="textPrimary"
                style={[styles.h6, styles.gutterBottom]}
              >
                Orientations
              </Text>
            </View>
            <View style={[styles.tableCell1]}>
              {orientationsDpc !== null && orientationsDpc.map((odpc) => (
                <Text style={styles.body3}>
                  {`${odpc.categorie}\n${odpc.nb_fiche_cadrage}. ${odpc.name}`}
                </Text>
              ))}
              <Text
                color="textSecondary"
                variant="subtitle2"
                style={styles.body1}
              >
                Préciser l&rsquo;année ou l&rsquo;arrêt auquel cette orientation fait référence
              </Text>
              <Text style={styles.body2}>
              2023-2025
              </Text>
            </View>
          </View>
          <View style={styles.hm3}>
            <View style={styles.tableRow}>
              <Text
                color=""
                style={[styles.h6, styles.gutterBottom]}
              >
                Programme de DPC
              </Text>
            </View>
            <Text style={styles.body1}>
              Intitulé du Programme
            </Text>
            <Text style={[styles.body2, styles.tableCell4]}>
              {formation.name}
            </Text>
            <Text
              color=""
              style={[styles.body1, styles.gutterBottom]}
            >
              Numéro d&rsquo;action  Numéro de session
            </Text>
            <Text style={styles.body2}>
              {formation.program_number}
              {'                             '}
              {formation.number_session}
            </Text>
          </View>
          <View style={styles.tableCell2}>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              Organisme de DPC
            </Text>
            <Text style={styles.body1}>
              Nom de l&rsquo;organisme
            </Text>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              HEALTH PERFORMANCE
            </Text>
            <Text style={styles.body1}>
              Adresse
            </Text>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              628 Route du val d&rsquo;Ardene 83200 Toulon
            </Text>
            <Text style={styles.body1}>
              Nom du responsable de l&rsquo;organisme de dpc
            </Text>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              David HUA
            </Text>
            <View style={styles.tableCellN} />
            <Image
              source="/static/tampon apteed.png"
              style={styles.brand2}
            />
          </View>
          <View />
        </View>
        <View style={styles.references2}>
          <View>
            <View style={styles.tableRow}>
              <Text
                color="textPrimary"
                style={[styles.h6, styles.gutterBottom]}
              >
                Conditions de participation
              </Text>
            </View>
            <View style={styles.tableCellN} />
            <Text style={styles.subtitle2}>
              Programme pluriannuel
            </Text>
            <Text style={styles.body2}>
              Non
            </Text>
            <Text
              color=""
              style={[styles.hm, styles.gutterBottom]}
            >
              Date de début
            </Text>
            <Text style={styles.body2}>
              {datestart}
            </Text>
          </View>
          <View style={styles.hm4}>
            <Text
              color=""
              style={[styles.hm, styles.gutterBottom]}
            >
              Date de fin
            </Text>
            <Text style={styles.body2}>
              {datefin}
            </Text>
            <Text
              color=""
              style={[styles.hm, styles.gutterBottom]}
            >
              Année civile de participation
            </Text>
            <Text style={styles.body2}>
              {moment(datestart, "DD/MM/YYYY").year()}
            </Text>
            <Text
              color=""
              style={[styles.hm, styles.gutterBottom]}
            >
              Durée
            </Text>
            <Text style={styles.body2}>
              {formation.dureeFormation}
              h
            </Text>
          </View>
          <View />
        </View>
      </Page>
    </Document>
  );
};

AttestationPDF.propTypes = {
  formation: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  orientations: PropTypes.array.isRequired,
};

export default AttestationPDF;
