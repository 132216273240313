/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import {
  // Box,
  Card,
  // CardHeader,
  // Divider,
  Typography,
  // Table,
  // TableBody,
  // TableCell,
  // TableRow,
  // CardContent,
  // ListItem,
  // List,
  Link,
  CircularProgress,
  TablePagination,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Backdrop,
  Box,
  Table,
  TableHead,
  TextField,
  Button
} from '@material-ui/core';
// import { customerApi } from '../../../api/customerApi';
// import useMounted from '../../../hooks/useMounted';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/styles';
import Scrollbar from '../Scrollbar';
import useMounted from '../../hooks/useMounted';
import { campaignApi } from '../../api/campaignApi';
import Label from '../Label';
// import Scrollbar from '../../Scrollbar';

const sortOptions = [
  {
    label: 'Plus récent',
    value: 'created_at|desc'
  },
  {
    label: 'Plus ancien',
    value: 'created_at|asc'
  },
];

const CampaignLogs = (props) => {
  const { idCampaign, ...other } = props;
  const mounted = useMounted();
  const [logs, setLogs] = useState([]);
  const [logsInfos, setLogsInfos] = useState({ categories: [], types: [], users: [] });
  // const [filteredLogs, setFilteredLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [numberLogs, setNumberLogs] = useState(0);
  const [limit, setLimit] = useState(25);
  // const [isSearching, setIsSearching] = useState(false);
  const [sort, setSort] = useState('created_at|desc');
  const [user, setUser] = useState('all');
  const [category, setCategory] = useState('all');
  const [type, setType] = useState('all');

  const usersOptions = logsInfos.users.map((userInfo) => ({ value: userInfo.userId, label: `${userInfo.firstname} ${userInfo.lastname}` }));
  usersOptions.unshift({
    value: 'all',
    label: 'Tous'
  });

  const categoriesOptions = logsInfos.categories.map((categoryInfo) => ({ value: categoryInfo.category, label: categoryInfo.category.charAt(0).toUpperCase() + categoryInfo.category.slice(1) }));
  categoriesOptions.unshift({
    value: 'all',
    label: 'Tous'
  });

  const typesOptions = logsInfos.types.map((typeInfo) => ({ value: typeInfo.type, label: typeInfo.type.charAt(0).toUpperCase() + typeInfo.type.slice(1) }));
  typesOptions.unshift({
    value: 'all',
    label: 'Tous'
  });

  const getLogs = useCallback(async () => {
    try {
      const data = await campaignApi.getCampaignLogs(idCampaign, sort, user, category, type,
        limit, page, localStorage.getItem('accessToken'));
      const dataInfos = await campaignApi.getCampaignLogsInfos(idCampaign, localStorage.getItem('accessToken'));
      //   console.log(data);
      //   console.log(dataInfos);
      if (mounted.current) {
        setLogs(data.logs);
        setLogsInfos(dataInfos);
        setNumberLogs(data.numberLogs.numberLogs);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  // pour afficher le loader uniquement dans le tableau
  const StyledPaper = withStyles({
    root: {
      position: 'relative'
    }
  })(Paper);
  const LimitedBackdrop = withStyles({
    root: {
      position: 'absolute',
      zIndex: 1
    }
  })(Backdrop);

  const handleCustomers = (async (sortChange, userChange, categoryChange, typeChange, limitChoice, pageChoice, displaySpinnerTable = false) => {
    try {
      // si le spinner de la page n'est pas actif (loading), on déclenche le spinner de la table des apprenants au chargement des données
      if (!isLoading && displaySpinnerTable) {
        setIsLoading(true);
      }
      // setIsSearching(true);
      const data = await campaignApi.getLogs(window.localStorage.getItem('accessToken'), idCampaign, sortChange, userChange, categoryChange, typeChange,
        limitChoice, pageChoice);
      setNumberLogs(data.numberLogs.numberLogs);
      // console.log(data);
      setLogs(data.logs);
      // setOptionsSearch(data.customers);
      setIsLoading(false);
      // setIsSearching(false);
      // setLoadingAutocomplete(false);
    } catch (err) {
      console.error(err);
    }
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(sort, user, category, type, limit, newPage, true);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);// handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, parseInt(event.target.value, 10), 0, modeRelance, true, true);
    handleCustomers(sort, user, category, type, event.target.value, 0, true);
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(event.target.value, user, category, type, limit, 0, true);
  };
  const handleUserChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setUser(value);
    } else {
      // console.log(event.target);
      setUser(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(sort, event.target.value, category, type, limit, 0, true);
  };

  const handleCategoryChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setCategory(value);
    } else {
      // console.log(event.target);
      setCategory(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(sort, user, event.target.value, type, limit, 0, true);
  };

  const handleTypeChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setType(value);
    } else {
      // console.log(event.target);
      setType(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(sort, user, category, event.target.value, limit, 0, true);
  };

  const reinitializeFilters = () => {
    // on met à jour le localstorage
    // window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
    // window.localStorage.setItem('choiceRelance', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
    // window.localStorage.setItem('choiceNewCallTrack', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
    // window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));
    // window.localStorage.setItem('choiceEndSession', JSON.stringify('all'));
    // window.localStorage.setItem('choiceSource', JSON.stringify('all'));
    // on réinitialise les filtres
    setSort('created_at|desc');
    setType('all');
    setCategory('all');
    setUser('all');
    // // on affiche les résultats réinitialisés
    handleCustomers('created_at|desc', 'all', 'all', 'all', limit, page, true);
  };

  return (
    <Card {...other}>
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        >
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              key="sort"
              label="Trier par date"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
              disabled={isLoading}
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              key="user"
              label="Utilisateur"
              name="user"
              onChange={handleUserChange}
              select
              SelectProps={{ native: true }}
              value={user}
              variant="outlined"
              disabled={isLoading}
                  // pour éviter la superposition dans le champ
              InputLabelProps={{ shrink: true }}
            >
              {usersOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              key="category"
              label="Catégorie"
              name="category"
              onChange={handleCategoryChange}
              select
              SelectProps={{ native: true }}
              value={category}
              variant="outlined"
              disabled={isLoading}
                  // pour éviter la superposition dans le champ
              InputLabelProps={{ shrink: true }}
            >
              {categoriesOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              key="type"
              label="Type"
              name="type"
              onChange={handleTypeChange}
              select
              SelectProps={{ native: true }}
              value={type}
              variant="outlined"
              disabled={isLoading}
                  // pour éviter la superposition dans le champ
              InputLabelProps={{ shrink: true }}
            >
              {typesOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          {/* <Box
                sx={{
                  m: 1
                }}
              >
                <TextField
                  key="user"
                  label="Utilisateur"
                  name="user"
                  onChange={handleUserChange}
                  select
                  SelectProps={{ native: true }}
                  value={currentSource}
                  variant="outlined"
                  disabled={isSearching}
                >
                  {userOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  m: 1,
                }}
              >
                <TextField
                  key="callTrackRelance"
                  label="Statut d'appel (Relance)"
                  name="callTrackRelance"
                  onChange={handleCallTrackRelanceChange}
                  select
                  SelectProps={{ native: true }}
                  value={currentCallTrackRelance}
                  variant="outlined"
                  disabled={isSearching}
                >
                  {callTrackRelanceOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  m: 1
                }}
              >
                <TextField
                  key="avancement"
                  label="Statut d'avancement"
                  name="avancement"
                  onChange={handleAvancementChange}
                  select
                  SelectProps={{ native: true }}
                  value={currentAvancement}
                  variant="outlined"
                  disabled={isSearching}
                >
                  {avancementOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box> */}
        </Box>
        <Button
          onClick={reinitializeFilters}
          color="primary"
          sx={{ ml: 2, mb: 1 }}
          variant="outlined"
        >
          Réinitialiser les filtres
        </Button>
      </Box>

      <StyledPaper>
        <Scrollbar>

          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Utilisateur
                  </TableCell>
                  <TableCell>
                    Catégorie
                  </TableCell>
                  <TableCell>
                    Type
                  </TableCell>
                  <TableCell>
                    Description
                  </TableCell>
                  <TableCell>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {logs.map((log, index) =>
                // const isCustomerSelected = selectedCustomers.includes(log.id);
                // eslint-disable-next-line implicit-arrow-linebreak
                  (
                    <TableRow
                      hover
                      key={log.id}
                    >
                      <TableCell>
                        {/* <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={log.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                  {...stringAvatar(`${log.firstname.replace(/ /g, '')} ${log.lastname.replace(/ /g, '')}`)}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color={user.id === log.first_source_id ? '#b32d00' : 'inherit'}
                                    component={RouterLink}
                                    to={`/dashboard/customers/${log.id}`}
                                    variant="subtitle2"
                                  >
                                    {log.firstname}
                                    {' '}
                                    {log.lastname}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {log.email}
                                  </Typography>
                                </Box>
                              </Box> */}

                        {log.userInfos.firstname}
                        {' '}
                        {log.userInfos.lastname}

                      </TableCell>
                      <TableCell>
                        {log.category === 'utilisateur' && (
                        <Label
                          color="success"
                        >
                          {log.category}
                        </Label>
                        )}
                        {log.category === 'notification' && (
                        <Label
                          color="error"
                        >
                          {log.category}
                        </Label>
                        )}
                        {log.category === 'apprenant' && (
                        <Label
                          color="warning"
                        >
                          {log.category}
                        </Label>
                        )}
                        {log.category === 'campagne' && (
                        <Label
                          color="secondary"
                        >
                          {log.category}
                        </Label>
                        )}
                        {log.category === 'session' && (
                        <Label
                          color="primary"
                        >
                          {log.category}
                        </Label>
                        )}
                        {log.category === 'formation' && (
                        <Label
                          color="formation"
                        >
                          {log.category}
                        </Label>
                        )}
                      </TableCell>
                      <TableCell>
                        {log.type.charAt(0).toUpperCase() + log.type.slice(1)}
                      </TableCell>
                      <TableCell>
                        {/* permet d'afficher le texte de la description avec le lien cliquable arrivant de la bdd */}
                        <div dangerouslySetInnerHTML={{ __html: log.description }} />
                      </TableCell>
                      <TableCell>
                        {moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </LimitedBackdrop>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </StyledPaper>
      <TablePagination
        component="div"
        count={numberLogs}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[15, 25, 50]}
        SelectProps={{
          disabled: isLoading
        }}
        backIconButtonProps={
                isLoading
                  ? { disabled: isLoading } : undefined
              }
        nextIconButtonProps={
                isLoading
                  ? { disabled: isLoading } : undefined
              }
      />
    </Card>
  );
};
CampaignLogs.propTypes = {
  idCampaign: PropTypes.number.isRequired
};

export default CampaignLogs;
