/* eslint-disable react/no-unknown-property */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  createFilterOptions,
  Grid,
  Link,
  // Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DataGrid, GridActionsCellItem, frFR, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { ProgressBar } from '.';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@mui/icons-material/Send';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import ListIcon from '@material-ui/icons/List';
import { sessionApi } from '../../../api/sessionApi';
import * as Yup from 'yup';
import { Formik } from 'formik';
import * as moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
// import AttestationPDF from '../../AttestationPDF';
// import { pdf } from '@react-pdf/renderer';
// import PDFMerger from 'pdf-merger-js';
// import * as XLSX from 'xlsx';
import Label from '../../Label';
import { customerApi } from '../../../api/customerApi';
import { ClickAwayListener } from '@mui/material';
import { useTheme } from '@emotion/react';

function renderProgress(params) {
  if (params.value === null) {
    return <ProgressBar value={Number(0)} />;
  }
  return <ProgressBar value={Number(parseInt(params.value, 10))} />;
}

const SessionCustomerListTable = (props) => {
  const { customersFixed, sessionId, customersList, session, formation, sources, relanceFixed, formations, getSession, setShowCheckUserDataAlert, ...other } = props;
  const [customers, setCustomers] = useState([]);
  const [relance, setRelance] = useState([]);
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [exporting, setExporting] = useState(false);
  const [show, setShow] = useState(false);
  const [showDec, setShowDec] = useState(false);
  const [currentIdDec, setCurrentIdDec] = useState(0);
  const { user } = useAuth();
  const theme = useTheme();
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');
  const [indexFormationDec, setIndexFormationDec] = useState(0);
  const [disableChoiceSession, setDisableChoiceSession] = useState(true);
  let sessionDec = 0;
  const [valueIdSession, setValueIdSession] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openCommonDialog, setOpenCommonDialog] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [dialogAction, setDialogAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customerToDeleteId, setCustomerToDeleteId] = useState(null);
  const [currentRowLearnerSessionId, setCurrentRowLearnerSessionId] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  const downloadAgrement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadAgrement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargement de la convention !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);

    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const downloadCerificate = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadCerificate(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargemen de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const downloadSwarnStatement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadSwarnStatement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargemen de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const sendAgreement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendAgreement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de la convention !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Convention de formation envoyée avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors de l\'envoi de la convention !');
    }
  };

  const sendCertificate = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendCertificate(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation de fin de formation envoyée avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  const sendSwarnStatement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendSwarnStatement(id, localStorage.getItem('accessToken'));
      console.clear();
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation sur l\'honneur envoyée avec succès !');
        getSession();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  // eslint-disable-next-line consistent-return
  const hasCompletedProfile = (row) => {
    if (row.adeli_rpps === '' || row.email === '' || row.firstname === '' || row.lastname === '' || row.profession === '' || row.civilite === '' || row.adress === '' || row.city === '' || row.zip === '') return false;
    return true;
  };

  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    ));

  const filterOptions = createFilterOptions({
    limit: 100,
    stringify: (option) => `${option.email} ${option.firstname} ${option.lastname}`,
  });

  const prices = {};
  prices['Médecin'] = formation.cost_med;
  prices.Infirmier = formation.cost_idel;
  prices['Masseur-kinésithérapeute'] = formation.cost_kine;
  prices.Pharmacien = formation.cost_pha;
  prices['Pédicure-podologue'] = formation.cost_podo;
  prices['Sage-Femme'] = formation.cost_sage;

  const handleShow = () => {
    setShow(!show);
  };

  const handleCloseDialog = () => {
    // setShowAlert(false);
    setShowDec(false);
  };

  useEffect(() => {
    setRows(customers.map((customer) => ({ id: customer.id,
      customers_id: customer.customers_id,
      firstname: customer.customerFirstname,
      lastname: customer.customerLastname,
      status: customer.status,
      primary_connection: customer.primary_connection !== null ? moment(customer.primary_connection).format('DD/MM/YYYY HH:mm:ss') : '',
      last_connection: customer.last_connection !== null ? moment(customer.last_connection).format('DD/MM/YYYY HH:mm:ss') : '',
      progression: customer.progression,
      duration: customer.duration,
      real_price: customer.real_price,
      source_id: customer.source_id,
      email: customer.email,
      adeli_rpps: customer.adeli_rpps,
      profession: customer.profession,
      decalages: customer.decalages,
      invoiced: customer.invoiced,
      agreement_sent: customer.agreement_sent,
      agreement_completed: customer.agreement_completed,
      honor_sent: customer.honor_sent,
      honor_completed: customer.honor_completed,
      certificate_sent: customer.certificate_sent,
      subscribed_at: customer.subscribed_at !== null ? moment(customer.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : '',
      call_track_relance: relance.filter((rel) => rel[0].customers_id === customer.customers_id),
      // dateLastRelance: customer.dateLastRelance !== null ? moment(customer.dateLastRelance).format('DD/MM/YYYY HH:mm:ss') : '',
      step: customer.step,
      tlms_percentage: customer.tlms_percentage,
      presence: customer.presence,
      totalProgress: customer.totalProgress
    })));
    // console.clear();
    // console.log(customers);
  }, [customers]);

  useEffect(() => {
    console.clear();
    // console.log(customersFixed);
    // console.log(customers);
  }, []);

  useEffect(() => {
    setCustomers(customersFixed);
    setRelance(relanceFixed.map((rel) => (rel[0] === undefined ? [{ id: 0, customers_id: 0, status: null, date: null, primary_connection: null, last_connection: null, real_price: null }] : rel))); // On enlève les valeurs vide du tableau des historique de relance
  }, [customersFixed]);

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await sessionApi.deleteCustomer(id, localStorage.getItem('accessToken'));
      toast.success('Apprenant supprimé !');
      const tempCustomers = [...customers];
      tempCustomers.splice(tempCustomers.findIndex((customer) => customer.id === id), 1);
      setCustomers(tempCustomers);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setIsLoading(false);
      setOpenDialogDelete(false);
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  const handleChange = async (params) => {
    // if (params.field === 'presence' || params.field === 'progression' || params.field === 'tlms_percentage') {
    //   // rows[0][params.field] = params.value;
    //   const row = rows.find((rowSession) => rowSession.id === params.id);
    //   console.log(rows);
    //   console.log(row);
    // }
    if (params.field === 'primary_connection' || params.field === 'last_connection' || params.field === 'subscribed_at') {
      params.value = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
    }
    if (params.field === 'real_price') {
      params.value = parseFloat(params.value).toFixed(3);
    }
    if (params.field === 'invoiced') {
      params.value = params.value === true ? 1 : false;
    }
    if (params.field === 'presence') {
      params.value = params.value === true ? 1 : false;
    }
    // console.log(params.row.customers_id);
    if (params.value === 'Décalage') {
      // On met la modale du choix de la session décalée à true
      setShowDec(true);
      setCurrentIdDec(params.id);
    } else {
      try {
        // console.log(params);
        await sessionApi.editCustomer(2, params.id, params.value, localStorage.getItem('accessToken'), params.field);
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  };

  const handleDecalage = async () => {
    try {
      await sessionApi.decalage(currentIdDec, sessionDec, localStorage.getItem('accessToken'));
      toast.success('Apprenant décalé !');
      handleCloseDialog();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la modification !');
    }
  };

  // téléchargement de la liste de présence pour les présentielle / virtuelle
  const handleDowloadAttendanceList = async () => {
    try {
      await customerApi.handleDowloadAttendanceList(sessionId, localStorage.getItem('accessToken'));
      toast.success('Téléchargement éffectué avec succès !');
    } catch (err) {
      console.error(err);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const handleExportPlateforme = async () => {
    try {
      setExporting(true);
      await sessionApi.subscribeNotLearners(sessionId, localStorage.getItem('accessToken'));
      setExporting(false);
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de l\'exportation !');
      setExporting(false);
    }
  };

  // const handleGetAttestationsDPC = async () => {
  //   try {
  //     const data = await sessionApi.getAttestationsDPC(sessionId, localStorage.getItem('accessToken'));
  //     const merger = new PDFMerger();

  //     await Promise.all(data.data.apprenants.map(async (customer) => {
  //       const element = await pdf(
  //         <AttestationPDF
  //           customer={customer}
  //           formation={data.data.formation}
  //           orientations={data.data.orientations}
  //         />
  //       ).toBlob();
  //       await merger.add(element);
  //     }));

  //     const mergedPdf = await merger.saveAsBlob();

  //     const fileURL = URL.createObjectURL(mergedPdf);
  //     window.open(fileURL, '_blank');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Il y a eu un souci lors de la génération des documents !');
  //   }
  // };

  const columnsFinal = [
    {
      field: 'firstname',
      headerName: 'Prénom',
      renderCell: (cellValues) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
          title={(
            <span style={{ whiteSpace: 'pre-line' }}>
              Email:
              {' '}
              {cellValues.row.email}
              {'\n'}
              RPPS:
              {' '}
              {cellValues.row.adeli_rpps}
              {'\n'}
              Profession:
              {' '}
              {cellValues.row.profession}
              {'\n'}
              {/* Inscription:
              {' '}
              {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''} */}
              1ère connexion:
              {' '}
              {cellValues.row.primary_connection}
              {'\n'}
              Dernière connexion:
              {' '}
              {cellValues.row.last_connection}
            </span>
      )}
        >
          <Link
            component={RouterLink}
            to={`/dashboard/customers/${cellValues.row.customers_id}`}
            variant="subtitle2"
          >
            {cellValues.row.firstname}
          </Link>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      renderCell: (cellValues) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
          title={(
            <span style={{ whiteSpace: 'pre-line' }}>
              Email:
              {' '}
              {cellValues.row.email}
              {'\n'}
              RPPS:
              {' '}
              {cellValues.row.adeli_rpps}
              {'\n'}
              Profession:
              {' '}
              {cellValues.row.profession}
              {'\n'}
              {/* Inscription:
              {' '}
              {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''} */}
              1ère connexion:
              {' '}
              {cellValues.row.primary_connection}
              {'\n'}
              Dernière connexion:
              {' '}
              {cellValues.row.last_connection}
            </span>
        )}
        >
          <Link
            component={RouterLink}
            to={`/dashboard/customers/${cellValues.row.customers_id}`}
            variant="subtitle2"
          >
            {cellValues.row.lastname}
          </Link>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: 'adeli_rpps',
      headerName: 'Adeli',
      width: 120,
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              color="inscritD"
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  color="inscritD"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                  color="secondary"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: true,
      valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation', 'À transférer'],
      width: 150,
    },
    {
      field: 'subscribed_at',
      headerName: 'Date d\'inscription',
      type: 'dateTime',
      width: 160,
      editable: user.access_level === 0,
    },
    // {
    //   field: 'primary_connection',
    //   headerName: 'Première connexion',
    //   editable: user.access_level === 0,
    //   type: 'dateTime',
    //   width: 160,
    // },
    // {
    //   field: 'last_connection',
    //   headerName: 'Dernière connexion',
    //   type: 'dateTime',
    //   editable: user.access_level === 0,
    //   width: 160,
    // },
    // {
    //   field: 'progression',
    //   headerName: 'Progression',
    //   renderCell: renderProgress,
    //   editable: user.access_level === 0,
    //   width: 160,
    // }
    { field: 'totalProgress',
      headerName: 'Progression',
      renderCell: renderProgress,
      width: 160
    },
  ];
  if (user.id_service === 4 || user.access_level === 0) {
    // if (formation.action_type === 'Programme intégré' && (formation.format === 'Mixte' || formation.format === 'Présentiel')) {
    //   columnsFinal.push({
    //     field: 'tlms_percentage',
    //     headerName: 'Progression tlms',
    //     renderCell: renderProgress,
    //     width: 160
    //   },);
    // }
    columnsFinal.push({ field: 'step',
      headerName: 'Étape',
      renderCell: (cellValues) => (
        cellValues.row.step === null ? 'Pas disponible' : cellValues.row.step
      ),
      width: 160
    },
    {
      editable: user.access_level === 0,
      field: 'duration',
      headerName: 'Durée',
      renderCell: (cellValues) => (
        cellValues.row.totalProgress === 100 ? cellValues.row.duration : ''
      ),
      width: 120,
    });
    // étape, durée
    // if (formation.format === 'Mixte' || formation.format === 'Présentiel') {
    //   columnsFinal.push({
    //     field: 'presence',
    //     headerName: 'Présence',
    //     renderCell: (cellValues) => (
    //       <Switch
    //         edge="end"
    //         checked={cellValues.row.presence}
    //         onChange={() => { cellValues.row.presence = !cellValues.row.presence; cellValues.value = cellValues.row.presence; handleChange(cellValues); }}
    //         defaultChecked={cellValues.row.presence}
    //         value={cellValues.row.presence}
    //         disabled={user.access_level !== 0}
    //       />
    //     ),
    //     editable: user.access_level === 0,
    //     width: 80,
    //   },);
    // }
  }
  columnsFinal.push({
    field: 'etat',
    headerName: 'État',
    renderCell: (cellValues) => {
      let etat = '';
      if (parseInt(cellValues.row.progression, 10) === 100) {
        if (formation.format === 'Mixte' || formation.format === 'Présentiel') {
          if (cellValues.row.presence) {
            etat = 'Terminé';
          } else {
            etat = 'En cours';
          }
        } else {
          etat = 'Terminé';
        }
      } else if (parseInt(cellValues.row.progression, 10) < 100 && parseInt(cellValues.row.progression, 10) > 0 && session.end_date >= moment().format('YYYY-MM-DD')) {
        etat = 'En cours';
      } else if (parseInt(cellValues.row.progression, 10) < 100 && parseInt(cellValues.row.progression, 10) > 0 && session.end_date < moment().format('YYYY-MM-DD')) {
        etat = 'Non terminé';
      } else if (parseInt(cellValues.row.progression, 10) === 0 && session.end_date >= moment().format('YYYY-MM-DD')) {
        etat = 'Pas commencé';
      } else if (parseInt(cellValues.row.progression, 10) === 0 && session.end_date < moment().format('YYYY-MM-DD')) { etat = 'Non terminé'; }
      return etat;
    },
    width: 120,
  },
  {
    field: 'real_price',
    headerName: 'Prix',
    valueFormatter: (params) => `${params.value} €`,
    editable: user.access_level === 0,
    width: 100,
  },
  {
    field: 'source_id',
    headerName: 'Source',
    type: 'singleSelect',
    valueOptions: sources.map((source) => source.id),
    valueFormatter: (params) => (sources.find((source) => source.id === params.value) ? `${sources.find((source) => source.id === params.value).firstname} ${sources.find((source) => source.id === params.value).lastname}` : ''),
    editable: user.access_level === 0,
    width: 125,
  },);
  if (user.id_service === 4 || user.access_level === 0) {
    columnsFinal.push({
      // {
    //   field: 'dateLastRelance',
    //   headerName: 'Dernière relance',
    //   width: 150,
    // },
    // {
    //   field: 'invoiced',
    //   headerName: 'Facturé',
    //   renderCell: (cellValues) => (
    //     <Switch
    //       edge="end"
    //       onChange={() => { cellValues.row.invoiced = !cellValues.row.invoiced; cellValues.value = cellValues.row.invoiced; handleChange(cellValues); }}
    //       defaultChecked={cellValues.row.invoiced}
    //       value={cellValues.row.invoiced}
    //       disabled={user.access_level !== 0}
    //     />
    //   ),
    //   editable: user.access_level === 0,
    //   width: 80,
    // }
      field: 'call_track_relance',
      headerName: 'Statut d\'appel',
      renderCell: (cellValues) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
          title={(
            <span style={{ whiteSpace: 'pre-line' }}>
              {cellValues.row.call_track_relance[0] !== undefined ? cellValues.row.call_track_relance[0].map((ctr) => `${ctr.status} ${moment(ctr.date).format('DD/MM/YYYY HH:mm:ss')}\n`) : ''}
            </span>
    )}
        >
          <Typography
            color="textPrimary"
            variant="body2"
          >
            {cellValues.row.call_track_relance[0] !== undefined ? cellValues.row.call_track_relance[0][0].status !== null ? cellValues.row.call_track_relance[0][0].status : '' : ''}
          </Typography>
        </Tooltip>
      ),
      width: 140,
    },);
  }
  // statut appel, dernière relance, facturé
  if (user.id_service === 4 || user.access_level === 0) {
    columnsFinal.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: (cellValues) => [
        <>
          {/* <GridActionsCellItem
            icon={<ListIcon />}
            onClick={() => handleLogs(id)}
            label="Fichier log"
            disabled={(user.id_service !== 0 && user.access_level !== 0) || (moment().year() !== moment(session.end_date).year())}
          /> */}
          {/* Permet de déterminer si le profil de l'apprenant est complet avant envoi d'attestation */}
          <div sx={{ marginRight: '10px' }}>
            {!hasCompletedProfile(cellValues.row) && <AdjustOutlinedIcon sx={{ fontSize: 10, color: 'red' }} />}
          </div>
          <Tooltip title="Convention de formation">
            <GridActionsCellItem
              icon={<SendIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.agreement_sent
                  ? `Vous êtes sur le point d\'envoyer la convention de formation de l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} pour signature. Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-agreement');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Envoyer la convention"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.agreement_completed
                ? '#7EB627'
                : cellValues.row.agreement_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <Tooltip title="Attestation de formation">
            <GridActionsCellItem
              icon={<AttachEmailIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.certificate_sent
                    ? `Vous êtes sur le point d\'envoyer l\'attestation de fin de formation de l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} . Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-certificate');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Attestation de formation"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.certificate_completed
                ? '#7EB627'
                : cellValues.row.certificate_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <Tooltip title="Attestation sur l'honneur">
            <GridActionsCellItem
              icon={<AssignmentIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.honor_sent 
                    ? `Vous êtes sur le point d\'envoyer l\'attestation sur l\'honneur à l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} . Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-swarn-statement');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Attestation sur l'honneur"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.honor_completed
                ? '#7EB627'
                : cellValues.row.honor_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => { setOpenDialogDelete(true); setCustomerToDeleteId(cellValues.row.id); }}
            label="Supprimer"
            disabled={user.id_service === 4}
          />
        </>
      ],
    });
  } else {
    columnsFinal.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => { setOpenDialogDelete(true); setCustomerToDeleteId(id); }}
          label="Supprimer"
          disabled={user.id_service !== 0 && user.access_level !== 0}
        />
      ],
    });
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  // const columns = [
  //   {
  //     field: 'firstname',
  //     headerName: 'Prénom',
  //     renderCell: (cellValues) => (
  //       <Tooltip
  //         followCursor
  //         enterDelay={500}
  //         placement="top"
  //         title={(
  //           <span style={{ whiteSpace: 'pre-line' }}>
  //             Email:
  //             {' '}
  //             {cellValues.row.email}
  //             {'\n'}
  //             RPPS:
  //             {' '}
  //             {cellValues.row.adeli_rpps}
  //             {'\n'}
  //             Profession:
  //             {' '}
  //             {cellValues.row.profession}
  //             {'\n'}
  //             Inscription:
  //             {' '}
  //             {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''}
  //           </span>
  //     )}
  //       >
  //         <Link
  //           component={RouterLink}
  //           to={`/dashboard/customers/${cellValues.row.customers_id}`}
  //           variant="subtitle2"
  //         >
  //           {cellValues.row.firstname}
  //         </Link>
  //       </Tooltip>
  //     ),
  //     width: 200,
  //   },
  //   {
  //     field: 'lastname',
  //     headerName: 'Nom',
  //     renderCell: (cellValues) => (
  //       <Tooltip
  //         followCursor
  //         enterDelay={500}
  //         placement="top"
  //         title={(
  //           <span style={{ whiteSpace: 'pre-line' }}>
  //             Email:
  //             {' '}
  //             {cellValues.row.email}
  //             {'\n'}
  //             RPPS:
  //             {' '}
  //             {cellValues.row.adeli_rpps}
  //             {'\n'}
  //             Profession:
  //             {' '}
  //             {cellValues.row.profession}
  //             {'\n'}
  //             Inscription:
  //             {' '}
  //             {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''}
  //           </span>
  //       )}
  //       >
  //         <Link
  //           component={RouterLink}
  //           to={`/dashboard/customers/${cellValues.row.customers_id}`}
  //           variant="subtitle2"
  //         >
  //           {cellValues.row.lastname}
  //         </Link>
  //       </Tooltip>
  //     ),
  //     width: 200,
  //   },
  //   {
  //     field: 'status',
  //     headerName: 'Statut',
  //     type: 'singleSelect',
  //     renderCell: (cellValues) => {
  //       let statut = '';
  //       if (cellValues.row.status === 'Inscrit') {
  //         statut = (
  //           <Label
  //             color="success"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
  //         statut = (
  //           <Label
  //             color="warning"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       } else if (cellValues.row.status === 'InscritD') {
  //         statut = (
  //           <Tooltip
  //             followCursor
  //             enterDelay={500}
  //             color="inscritD"
  //             placement="top"
  //             title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
  //               <span style={{ whiteSpace: 'pre-line' }}>
  //                 {`${dec.name} session: ${dec.number_session}`}
  //                 {'\n'}
  //               </span>
  //             )) : null}
  //           >
  //             <span>
  //               <Label
  //                 color="inscritD"
  //               >
  //                 {cellValues.row.status}
  //               </Label>
  //             </span>
  //           </Tooltip>
  //         );
  //       } else if (cellValues.row.status === 'Décalage') {
  //         statut = (
  //           <Tooltip
  //             followCursor
  //             enterDelay={500}
  //             placement="top"
  //             title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
  //               <span style={{ whiteSpace: 'pre-line' }}>
  //                 {`${dec.name} session: ${dec.number_session}`}
  //                 {'\n'}
  //               </span>
  //             )) : null}
  //           >
  //             <span>
  //               <Label
  //                 InputLabelProps={{ style: { pointerEvents: 'auto' } }}
  //                 color="secondary"
  //               >
  //                 {cellValues.row.status}
  //               </Label>
  //             </span>
  //           </Tooltip>
  //         );
  //       } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
  //         statut = (
  //           <Label
  //             color="error"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       }
  //       return (statut);
  //     },
  //     editable: true,
  //     valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation', 'À transférer'],
  //     width: 150,
  //   },
  //   {
  //     field: 'primary_connection',
  //     headerName: 'Première connexion',
  //     editable: user.access_level === 0,
  //     type: 'dateTime',
  //     width: 160,
  //   },
  //   {
  //     field: 'last_connection',
  //     headerName: 'Dernière connexion',
  //     type: 'dateTime',
  //     editable: user.access_level === 0,
  //     width: 160,
  //   },
  //   {
  //     field: 'progression',
  //     headerName: 'Progression',
  //     renderCell: renderProgress,
  //     editable: user.access_level === 0,
  //     width: 160,
  //   },
  //   {
  //     field: 'etat',
  //     headerName: 'État',
  //     renderCell: (cellValues) => {
  //       let etat = '';
  //       if (cellValues.row.progression === 100) {
  //         etat = 'Terminé';
  //       } else if (cellValues.row.progression < 100 && cellValues.row.progression > 0 && session.end_date >= moment().format('YYYY-MM-DD')) {
  //         etat = 'En cours';
  //       } else if (cellValues.row.progression < 100 && cellValues.row.progression > 0 && session.end_date < moment().format('YYYY-MM-DD')) {
  //         etat = 'Non terminé';
  //       } else if (cellValues.row.progression === 0 && session.end_date >= moment().format('YYYY-MM-DD')) {
  //         etat = 'Pas commencé';
  //       } else if (cellValues.row.progression === 0 && session.end_date < moment().format('YYYY-MM-DD')) { etat = 'Non terminé'; }
  //       return etat;
  //     },
  //     width: 120,
  //   },
  //   // {
  //   //   field: 'duration',
  //   //   headerName: 'Durée',
  //   //   editable: user.access_level === 0,
  //   //   width: 120,
  //   // },
  //   {
  //     field: 'real_price',
  //     headerName: 'Prix',
  //     valueFormatter: (params) => `${params.value} €`,
  //     editable: user.access_level === 0,
  //     width: 100,
  //   },
  //   {
  //     field: 'source_id',
  //     headerName: 'Source',
  //     type: 'singleSelect',
  //     valueOptions: sources.map((source) => source.id),
  //     valueFormatter: (params) => (sources.find((source) => source.id === params.value) ? `${sources.find((source) => source.id === params.value).firstname} ${sources.find((source) => source.id === params.value).lastname}` : ''),
  //     editable: user.access_level === 0,
  //     width: 125,
  //   },
  //   {
  //     field: 'actions',
  //     type: 'actions',
  //     headerName: 'Actions',
  //     width: 80,
  //     cellClassName: 'actions',
  //     getActions: ({ id }) => [

  //       <GridActionsCellItem
  //         icon={<DeleteIcon />}
  //         onClick={() => handleDelete(id)}
  //         label="Supprimer"
  //         disabled={user.id_service !== 0 && user.access_level !== 0}
  //       />
  //     ],
  //   },
  // ];

  // const columns2 = [
  //   {
  //     field: 'firstname',
  //     headerName: 'Prénom',
  //     renderCell: (cellValues) => (
  //       <Tooltip
  //         followCursor
  //         enterDelay={500}
  //         placement="top"
  //         title={(
  //           <span style={{ whiteSpace: 'pre-line' }}>
  //             Email:
  //             {' '}
  //             {cellValues.row.email}
  //             {'\n'}
  //             RPPS:
  //             {' '}
  //             {cellValues.row.adeli_rpps}
  //             {'\n'}
  //             Profession:
  //             {' '}
  //             {cellValues.row.profession}
  //             {'\n'}
  //             Inscription:
  //             {' '}
  //             {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''}
  //           </span>
  //     )}
  //       >
  //         <Link
  //           component={RouterLink}
  //           to={`/dashboard/customers/${cellValues.row.customers_id}`}
  //           variant="subtitle2"
  //         >
  //           {cellValues.row.firstname}
  //         </Link>
  //       </Tooltip>
  //     ),
  //     width: 200,
  //   },
  //   {
  //     field: 'lastname',
  //     headerName: 'Nom',
  //     renderCell: (cellValues) => (
  //       <Tooltip
  //         followCursor
  //         enterDelay={500}
  //         placement="top"
  //         title={(
  //           <span style={{ whiteSpace: 'pre-line' }}>
  //             Email:
  //             {' '}
  //             {cellValues.row.email}
  //             {'\n'}
  //             RPPS:
  //             {' '}
  //             {cellValues.row.adeli_rpps}
  //             {'\n'}
  //             Profession:
  //             {' '}
  //             {cellValues.row.profession}
  //             {'\n'}
  //             Inscription:
  //             {' '}
  //             {cellValues.row.subscribed_at !== null ? moment(cellValues.row.subscribed_at).format('DD/MM/YYYY HH:mm:ss') : ''}
  //           </span>
  //       )}
  //       >
  //         <Link
  //           component={RouterLink}
  //           to={`/dashboard/customers/${cellValues.row.customers_id}`}
  //           variant="subtitle2"
  //         >
  //           {cellValues.row.lastname}
  //         </Link>
  //       </Tooltip>
  //     ),
  //     width: 200,
  //   },
  //   {
  //     field: 'status',
  //     headerName: 'Statut',
  //     type: 'singleSelect',
  //     renderCell: (cellValues) => {
  //       let statut = '';
  //       if (cellValues.row.status === 'Inscrit') {
  //         statut = (
  //           <Label
  //             color="success"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
  //         statut = (
  //           <Label
  //             color="warning"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       } else if (cellValues.row.status === 'InscritD') {
  //         statut = (
  //           <Tooltip
  //             followCursor
  //             enterDelay={500}
  //             color="inscritD"
  //             placement="top"
  //             title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
  //               <span style={{ whiteSpace: 'pre-line' }}>
  //                 {`${dec.name} session: ${dec.number_session}`}
  //                 {'\n'}
  //               </span>
  //             )) : null}
  //           >
  //             <span>
  //               <Label
  //                 color="inscritD"
  //               >
  //                 {cellValues.row.status}
  //               </Label>
  //             </span>
  //           </Tooltip>
  //         );
  //       } else if (cellValues.row.status === 'Décalage') {
  //         statut = (
  //           <Tooltip
  //             followCursor
  //             enterDelay={500}
  //             placement="top"
  //             title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
  //               <span style={{ whiteSpace: 'pre-line' }}>
  //                 {`${dec.name} session: ${dec.number_session}`}
  //               </span>
  //             )) : null}
  //           >
  //             <Label
  //               color="secondary"
  //             >
  //               {cellValues.row.status}
  //             </Label>
  //           </Tooltip>
  //         );
  //       } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
  //         statut = (
  //           <Label
  //             color="error"
  //           >
  //             {cellValues.row.status}
  //           </Label>
  //         );
  //       }
  //       return (statut);
  //     },
  //     editable: true,
  //     valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation'],
  //     width: 80,
  //   },
  //   {
  //     field: 'primary_connection',
  //     headerName: 'Première connexion',
  //     width: 160,
  //   },
  //   {
  //     field: 'last_connection',
  //     headerName: 'Dernière connexion',
  //     width: 160,
  //   },
  //   {
  //     field: 'progression',
  //     headerName: 'Progression',
  //     renderCell: renderProgress,
  //     width: 160,
  //   },
  // ];

  // if (formation.action_type === 'Programme intégré' && (formation.format === 'Mixte' || formation.format === 'Non présentiel')) {
  //   columns2.push({
  //     field: 'tlms_percentage',
  //     headerName: 'Progression tlms',
  //     renderCell: renderProgress,
  //     width: 160
  //   },);
  // }

  // columns2.push(
  //   { field: 'step',
  //     headerName: 'Étape',
  //     width: 160
  //   },
  //   {
  //     editable: user.access_level === 0,
  //     field: 'duration',
  //     headerName: 'Durée',
  //     // type: 'date',
  //     width: 120,
  //   },
  //   {
  //     field: 'real_price',
  //     headerName: 'Prix',
  //     valueFormatter: (params) => `${params.value} €`,
  //     editable: user.access_level === 0,
  //     width: 100,
  //   },
  //   {
  //     field: 'source_id',
  //     headerName: 'Source',
  //     type: 'singleSelect',
  //     valueOptions: sources.map((source) => source.id),
  //     valueFormatter: (params) => (sources.find((source) => source.id === params.value) ? `${sources.find((source) => source.id === params.value).firstname} ${sources.find((source) => source.id === params.value).lastname}` : ''),
  //     editable: true,
  //     width: 125,
  //   },
  //   {
  //     field: 'call_track_relance',
  //     headerName: 'Statut d\'appel',
  //     renderCell: (cellValues) => (
  //       <Tooltip
  //         followCursor
  //         enterDelay={500}
  //         placement="top"
  //         title={(
  //           <span style={{ whiteSpace: 'pre-line' }}>
  //             {cellValues.row.call_track_relance[0] !== undefined ? cellValues.row.call_track_relance[0].map((ctr) => `${ctr.status} ${moment(ctr.date).format('DD/MM/YYYY HH:mm:ss')}\n`) : ''}
  //           </span>
  //   )}
  //       >
  //         <Typography
  //           color="textPrimary"
  //           variant="body2"
  //         >
  //           {cellValues.row.call_track_relance[0] !== undefined ? cellValues.row.call_track_relance[0][0].status !== null ? cellValues.row.call_track_relance[0][0].status : '' : ''}
  //         </Typography>
  //       </Tooltip>
  //     ),
  //     width: 140,
  //   },
  //   {
  //     field: 'dateLastRelance',
  //     headerName: 'Dernière relance',
  //     width: 150,
  //   },
  //   {
  //     field: 'invoiced',
  //     headerName: 'Facturé',
  //     renderCell: (cellValues) => (
  //       <Switch
  //         edge="end"
  //         onChange={() => { cellValues.row.invoiced = !cellValues.row.invoiced; cellValues.value = cellValues.row.invoiced; handleChange(cellValues); }}
  //         defaultChecked={cellValues.row.invoiced}
  //         value={cellValues.row.invoiced}
  //         disabled={user.access_level !== 0}
  //       />
  //     ),
  //     editable: user.access_level === 0,
  //     width: 80,
  //   },
  //   {
  //     field: 'actions',
  //     type: 'actions',
  //     headerName: 'Actions',
  //     width: 80,
  //     cellClassName: 'actions',
  //     getActions: ({ id }) => [
  //       <GridActionsCellItem
  //         icon={<ListIcon />}
  //         onClick={() => handleLogs(id)}
  //         label="Fichier log"
  //         disabled={(user.id_service !== 0 && user.access_level !== 0) || (moment().year() !== moment(session.end_date).year())}
  //       />,
  //       <GridActionsCellItem
  //         icon={<DeleteIcon />}
  //         onClick={() => handleDelete(id)}
  //         label="Supprimer"
  //       />,
  //     ],
  //   },
  // );

  return (
    <Formik
      initialValues={{ addCustomer: null }}
      enableReinitialize
      validationSchema={Yup
        .object()
        .shape({
          addCustomer: Yup.number().nullable()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.addCustomer !== null) {
            const jsonAnswer = await sessionApi.addCustomer(sessionId, user.id, values, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            setSubmitting(false);
            toast.success('Apprenant inscrit !');
            const tempCustomers = [...customers];
            tempCustomers.unshift(jsonAnswer.data);
            setCustomers(tempCustomers);
            values.addCustomer = null;
          }
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'inscription !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting, touched, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Card {...other}>
            <Dialog
              open={openCommonDialog}
              onClose={() => setOpenCommonDialog(false)}
            >
              <DialogContent>
                <DialogContentText>
                  { dialogText }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (dialogAction === 'send-agreement') {
                      downloadAgrement(currentRowLearnerSessionId);
                    } else if (dialogAction === 'send-certificate') {
                      downloadCerificate(currentRowLearnerSessionId);
                    } else if (dialogAction === 'send-swarn-statement') {
                      downloadSwarnStatement(currentRowLearnerSessionId);
                    }
                  }}
                  color="primary"
                  disabled={isLoading}
                >
                  Télécharger
                </Button>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={(
                    <Alert
                      icon={false}
                      style={{ backgroundColor: theme.palette.mode !== 'light' && 'transparent' }}
                      sx={{ margin: 0 }}
                    >
                      <Grid
                        container
                        spacing={0}
                      >
                        <Grid
                          item
                          sx={8}
                        >
                          Attention - Cette action est irréversible !
                        </Grid>
                        <Grid
                          item
                          sx={4}
                        >
                          <Button
                            onClick={() => {
                              if (dialogAction === 'send-agreement') {
                                sendAgreement(currentRowLearnerSessionId);
                              } else if (dialogAction === 'send-certificate') {
                                sendCertificate(currentRowLearnerSessionId);
                              } else if (dialogAction === 'send-swarn-statement') {
                                sendSwarnStatement(currentRowLearnerSessionId);
                              }
                            }}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            sx={{ mt: 1 }}
                            disabled={isLoading}
                          >
                            Confirmer
                          </Button>
                        </Grid>
                      </Grid>
                    </Alert>
                  )}
                  >
                    <Button 
                      color="primary"
                      onClick={handleTooltipOpen}>
                      Je souhaite envoyer
                    </Button>
                  </Tooltip>
                </ClickAwayListener>
                <Button
                  onClick={() => setOpenCommonDialog(false)}
                  color="primary"
                >
                  Annuler
                </Button>
                {isLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
                )}
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialogDelete}
              onClose={() => setOpenDialogDelete(false)}
            >
              <DialogContent>
                <DialogContentText>
                  Souhaitez-vous vraiment supprimer l&apos;apprenant ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleDelete(customerToDeleteId)}
                  color="primary"
                  disabled={isLoading}
                >
                  Supprimer
                </Button>
                <Button
                  onClick={() => setOpenDialogDelete(false)}
                  color="primary"
                >
                  Annuler
                </Button>
                {isLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
                )}
              </DialogActions>
            </Dialog>
            <Dialog
              open={showDec}
              onClose={handleCloseDialog}
            >
              <DialogTitle>
                Décalage
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.name} ${option.program_number}`}
                        options={formations}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setDisableChoiceSession(false);
                            setIndexFormationDec(formations.indexOf(value));
                            setFieldValue(
                              'choiceFormation',
                              value.id
                            );
                          } else {
                            setDisableChoiceSession(true);
                            setIndexFormationDec(0);
                          }
                          sessionDec = 0;
                          setValueIdSession(!valueIdSession);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une formation"
                            name="choiceFormation"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
          // pour réinitialiser le autocomplete on lui change la valeur de sa key, quand on change de formation, cela réinitialise le champ
                        key={valueIdSession}
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')}`}
                        options={formations[indexFormationDec].sessions}
                        onChange={(e, value) => {
                          setFieldValue(
                            'choiceSession',
                            value !== null ? value.id : null
                          );
                          sessionDec = value.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une session"
                            name="choiceFormation"
                            variant="outlined"
                            disabled={disableChoiceSession}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  Annuler
                </Button>
                <Button
                  onClick={handleDecalage}
                  color="primary"
                >
                  Enregistrer
                </Button>
              </DialogActions>
            </Dialog>
            {/* <Dialog
              open={showAlert}
              onClose={handleCloseDialog}
            >
              <DialogTitle>
                Différence de dates
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {alertMessage}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  Annuler
                </Button>
              </DialogActions>
            </Dialog> */}
            {!show && (
            <Button
              color="primary"
              sx={{
                ml: 160,
                m: 2,
              }}
              variant="outlined"
              onClick={handleShow}
            >
              + Ajouter un apprenant
            </Button>
            )}
            {show && (
            <>
              <Grid
                item
                md={4}
                sx={12}
              >
                <Autocomplete
                  filterOptions={filterOptions}
                  key={isSubmitting}
                  getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                  options={customersList.filter((customer) => !customers.map((c) => c.customers_id).includes(customer.id))}
                  onChange={(e, value) => {
                    setFieldValue(
                      'addCustomer',
                      value !== null ? value.id : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.addCustomer && errors.addCustomer)}
                      fullWidth
                      helperText={touched.addCustomer && errors.addCustomer}
                      label="Chercher un apprenant à ajouter"
                      name="addCustomer"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Button
                color="primary"
                disabled={isSubmitting}
                sx={{
                  ml: 160,
                  m: 2,
                }}
                variant="outlined"
                type="submit"
              >
                Ajouter l&apos;apprenant
              </Button>
              <Button
                color="primary"
                sx={{
                  ml: 160,
                  m: 2,
                }}
                variant="outlined"
                onClick={handleShow}
              >
                Fermer l&apos;ajout
              </Button>
            </>
            )}
            <Button
              color="primary"
              sx={{
                ml: 160,
                m: 2,
              }}
              variant="outlined"
              onClick={handleDowloadAttendanceList}
            >
              Télécharger la liste de présence
            </Button>
            {/* <Button
              color="primary"
              sx={{
                ml: 160,
                m: 2,
              }}
              variant="outlined"
              onClick={handleExportPlateforme}
              disabled
            >
              Exporter vers la plateforme
            </Button> */}
            {/* <Button
              color="primary"
              onClick={handleGetAttestationsDPC}
              sx={{ m: 1 }}
              variant="outlined"
              disabled={user.access_level !== 0}
            >
              Générer les attestations DPC
            </Button>
            <Button
              color="primary"
              onClick={handleGetTracabilite}
              sx={{ m: 1 }}
              variant="outlined"
              disabled={user.access_level !== 0 || moment().year() !== moment(session.end_date).year()}
            >
              Générer la traçabilité
            </Button> */}
            <Box>
              <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'lastname', sort: 'asc' }],
                  },
                }}
                autoHeight
                rows={rows}
                // columns={user.id_service === 4 || user.access_level === 0 ? columns2 : columns}
                columns={columnsFinal}
                pageSize={100}
                rowsPerPageOptions={[5]}
                onCellEditCommit={handleChange}
                components={{ Toolbar: CustomToolbar }}
              />
            </Box>

          </Card>

        </form>

      )}

    </Formik>

  );
};

SessionCustomerListTable.propTypes = {
  customersFixed: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  getSession: PropTypes.func,
  customersList: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  formation: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired,
  relanceFixed: PropTypes.array,
  orientations: PropTypes.array.isRequired,
  formations: PropTypes.array.isRequired,
  setShowCheckUserDataAlert: PropTypes.bool.isRequired
};

export default SessionCustomerListTable;
